export enum StaticDataType {
    INSTITUTE_TYPE,
    INSTITUTE_BENEFIT,
    EDUCATION_LEVEL,
	COURSE_DURATION,
	COURSE_CATEGORY,
	COURSE_DISCIPLINE,
	COURSE_INTAKE_STATUS,
	ENGLISH_EXAM_TYPE,
	GRADING_SCHEME,
	DOCUMENT_TYPE,
	DOCUMENT_TYPE_VISA_APPLICABLE,
	DOCUMENT_TYPE_PARTNER,
	DOCUMENT_TYPE_PARTNER_CERT,
	COURSE_APPL_STATUS,
	PAYMENT_STATUS,
	REGION,
	ENGAGEMENT_LEVEL,
	GENDER,
	VISA_TYPE,
	LEAD_SOURCE,
	LEAD_STATUS

}