

<!-- partners -->
<app-partners></app-partners>

<div class="before-footer">
    <section>
        <article>
            <img src="assets/images/location-icon-large.svg" alt="location" width="" height="">
            <h4>Address</h4>
            <p>Unit 5, 7900 Hurontario St Brampton, ON L6Y 0P6</p>
        </article>
        <article>
            <img src="assets/images/phone-icon-large.svg" alt="phone" width="" height="">
            <h4>Phone Number</h4>
            <p><a href="tel:1844-834-4400">1844-834-4400</a></p>
        </article>
        <article>
            <img src="assets/images/email-icon-large.svg" alt="email" width="" height="">
            <h4>Email</h4>
            <p><a href="mailto:admissions@onlineofferletter.com">admissions&#64;onlineofferletter.com</a></p>
        </article>
    </section>
</div>
<footer>
    <section>
        <article>
            <img src="assets/images/logo-footer.png" alt="" width="200" height="">
        </article>
        <article [ngClass]="{'pl-5': layoutService.isDesktop()}">
            <h4>OUR SITEMAP</h4>
                <table class="w-100 footer-site-map">
                    <tr>
                        <td><a [routerLink]="['/home']">Home</a></td>
                        <td><a [routerLink]="['/services']"> Our Services</a></td>
                    </tr>
                    <tr>
                        <td><a [routerLink]="['/benefits']">Benefits</a></td>
                        <td><a [routerLink]="['/about']"> About Us</a></td>
                    </tr>
                    <tr>
                        <td><a [routerLink]="['/auth/signin']">Login</a></td>
                        <td><a [routerLink]="['/contact']">Contact</a></td>
                    </tr>
                </table>
        </article>
        <article>
            <h4>KEEP IN TOUCH</h4>
            <p>Sign Up to Our Newsletter to Get Latest Updates on services, Offers and Immigration</p>
            <div *ngIf="apiError">
                <div *ngIf="apiError.message" class="alert alert-dismissible fade show error-container alert-success" role="alert">
                    <button class="close" (click)="onClickClose()">
                        <i class="material-icons">close</i>
                    </button>
                    <span>{{ apiError.message }}</span>
                </div>
            </div>
            <form id="sign-up-form-footer" [formGroup]="newsletterForm" (ngSubmit)="onSubmit()" style="display: inline;">
                <input type="email" placeholder="Enter your Email" formControlName="email">
                <button type="submit"><img src="assets/images/arrow-right.svg" alt="arrow" width="" height=""></button>
            </form>
            <div *ngIf="formUtils.showValidationHint(newsletterForm,'email', 'required')" class="input-hint">
                {{ 'message.validation.required' | translate }}
            </div>
        </article>
    </section>
    <div id="socials">
        <article>
            <a href="https://www.facebook.com/onlineofferletter/" target="blank"><img src="./assets/images/facebook_iconfacebook.svg" alt="facebook" width="" height=""></a>
            <a href="https://www.instagram.com/onlineofferletter/" target="blank"><img src="./assets/images/insta_icon-instagram.svg" alt="instagram" width="" height=""></a>
            <a href="#"><img src="./assets/images/youtube_iconyoutube.svg" alt="youtube" width="" height=""></a>
        </article>
    </div>
    <hr>
    <div id="copyright">
        <article>
        <p>&copy; 2021 College Admin. All Rights Reserved.</p></article>
        <article>
        <p>Designed by Digify SEO Company</p></article>
    </div>
</footer>



<!-- <footer id="rs-footer" class="bg3 rs-footer">
    <div class="container">
        <div>
            <div class="row footer-contact-desc">
                <div class="col-md-4">
                    <div class="contact-inner">
                        <i class="fa fa-map-marker"></i>
                        <h4 class="contact-title">Address</h4>
                        <p class="contact-desc">
                            Unit 5, 7900 Hurontario
                            <br />
                            St Brampton, ON L6Y 0P6

                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contact-inner">
                        <i class="fa fa-phone"></i>
                        <h4 class="contact-title">Phone Number</h4>
                        <p class="contact-desc">
                           <a href="callto:+ 1844-834-4400"><span class="footer-txt"> + 1844-834-4400</span></a><br>
                           

                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="contact-inner">
                        <i class="fa fa-envelope"></i>
                        <h4 class="contact-title">Email Address</h4>
                        <p class="contact-desc">
                            <a href="mailto:admissions@onlineofferletter.com"><span class="footer-txt">admissions@onlineofferletter.com</span></a>
                            <br>
                            

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="about-widget">
                        <img src="assets/images/logo-footer.png" alt="Footer Logo" style="margin-top:-25px;">
                        
                        
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <h5 class="footer-title">OUR SITEMAP</h5>
                    <ul class="sitemap-widget">

                        <li><a [routerLink]="['/home']">Home</a></li>
                        <li><a [routerLink]="['/about']">About Us </a></li>
                        <li><a [routerLink]="['/benefits']">Benefits </a></li>
                        <li><a [routerLink]="['/services']">Our Services </a></li>
                        <li><a [routerLink]="['/auth/signin']">Login </a></li>
                        <li><a [routerLink]="['/contact']">Contact  </a></li>
                        <li><a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy  </a></li>

                    </ul>
                </div>
                <div class="col-lg-4 col-md-12">
                    <h5 class="footer-title">KEEP IN TOUCH</h5>
                    <p>Sign Up to Our Newsletter to Get Latest Updates on services, Offers and Immigration.</p>

                    <div *ngIf="apiError">
                        <div *ngIf="apiError.message" class="alert alert-dismissible fade show error-container alert-success" role="alert">
                            <button class="close" (click)="onClickClose()">
                                <i class="material-icons">close</i>
                            </button>
                            <span>{{ apiError.message }}</span>
                        </div>
                    </div>

                    <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()" class="news-form">
                        <input type="text" class="form-input" placeholder="Enter Your Email" formControlName="email">
                        <button type="submit" class="form-button"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                    </form>
                    <div *ngIf="formUtils.showValidationHint(newsletterForm,'email', 'required')" class="input-hint">
                        {{ 'message.validation.required' | translate }}
                    </div>
                </div>
            </div>
            <div class="footer-share">
                <ul>
                    <li><a href="https://www.facebook.com/onlineofferletter/" target="blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/onlineofferletter/" target="blank"><i class="fa fa-instagram"></i></a></li>
                    <li><a href="#"><i class="fa fa-youtube"></i></a></li>

                </ul>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container">
            <div class="copyright">
                <ul>
                    <li style="font-size: 12px;">
                        © 2020 <a href="#">Online Offer Letter</a>. All Rights Reserved.
                    </li>
                    <li style="font-size: 12px;">
                        Designed by <a href="https://www.digifyseocompany.com/" target="blank">Digify SEO Company</a>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</footer> -->

<!-- <div class="fixed-bottom">
    This is fixed at bottom
</div> -->

