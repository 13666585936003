import { environment } from '../environments/environment';

export const AppConstants = {
    endpoint: {

        attachment: {
            base: environment.apiBaseUrl + '/attachments',
            get: environment.apiBaseUrl + '/attachments/{id}',
            delete: environment.apiBaseUrl + '/attachments/{id}/delete'
        },
        
        auth: {
            login: environment.apiBaseUrl + '/auth/login',
            loginSocial: environment.apiBaseUrl + '/auth/login/social',
            activate: environment.apiBaseUrl + '/auth/account/activate',
            activate_request: environment.apiBaseUrl + '/auth/account/activate-request',
            password: {
                forgot: environment.apiBaseUrl + '/auth/password/forgot',
                reset: environment.apiBaseUrl + '/auth/password/reset'
            },
            user_profile: environment.apiBaseUrl + '/user-profile'
        },
        register: {
            user: environment.apiBaseUrl + '/register',
            social: environment.apiBaseUrl + '/register/social'
        },
        blog: {
            base: environment.apiBaseUrl + '/blogs',
            summaries: environment.apiBaseUrl + '/blogs/summaries',
            get: environment.apiBaseUrl + '/blogs/{id}',
            general_info: environment.apiBaseUrl + '/blogs/{id}/general-info',
            content: environment.apiBaseUrl + '/blogs/{id}/content',
            image: environment.apiBaseUrl + '/blogs/{id}/image',
            publish: environment.apiBaseUrl + '/blogs/{id}/publish',
            unpublish: environment.apiBaseUrl + '/blogs/{id}/unpublish',
            delete: environment.apiBaseUrl + '/blogs/{id}/delete'
        },
        institute: {
            base: environment.apiBaseUrl + '/institutes',
            summaries: environment.apiBaseUrl + '/institutes/summaries',
            get: environment.apiBaseUrl + '/institutes/{id}',
            general_info: environment.apiBaseUrl + '/institutes/{id}/general-info',
            about: environment.apiBaseUrl + '/institutes/{id}/about',
            benefits: environment.apiBaseUrl + '/institutes/{id}/benefits',
            images: environment.apiBaseUrl + '/institutes/{id}/images',
            publish: environment.apiBaseUrl + '/institutes/{id}/publish',
            delete: environment.apiBaseUrl + '/institutes/{id}/delete',
            courses: environment.apiBaseUrl + '/institutes/{id}/courses'
        },
        company: {
            base: environment.apiBaseUrl + '/companies',
            get: environment.apiBaseUrl + '/companies/{id}'
        },
        agency: {
            base: environment.apiBaseUrl + '/agencies',
            get: environment.apiBaseUrl + '/agencies/{id}'
        },
        course: {
            base: environment.apiBaseUrl + '/courses',
            summaries: environment.apiBaseUrl + '/courses/summaries',
            get: environment.apiBaseUrl + '/courses/{id}',
            check_eligibility: environment.apiBaseUrl + '/courses/{id}/check-eligibility',
            general_info: environment.apiBaseUrl + '/courses/{id}/general-info',
            description: environment.apiBaseUrl + '/courses/{id}/description',
            admission_req: environment.apiBaseUrl + '/courses/{id}/admission-req',
            fees: environment.apiBaseUrl + '/courses/{id}/fees',
            date: environment.apiBaseUrl + '/courses/{id}/dates',
            requirement_eng: environment.apiBaseUrl + '/courses/{id}/requirement/english',
            requirement_academic: environment.apiBaseUrl + '/courses/{id}/requirement/academic',
            requirement_general: environment.apiBaseUrl + '/courses/{id}/requirement/general',
            publish: environment.apiBaseUrl + '/courses/{id}/publish',
            delete: environment.apiBaseUrl + '/courses/{id}/delete',
            getCourseDateDetails: environment.apiBaseUrl + '/courses/getCourseDateDetails/{id}'
        },
        course_application: {
            base: environment.apiBaseUrl + '/course-applications',
            get: environment.apiBaseUrl + '/course-applications/{id}',            
            documents: environment.apiBaseUrl + '/course-applications/{id}/document',
            stats: environment.apiBaseUrl + '/course-applications/stats',
        },
        referral: {
            base: environment.apiBaseUrl + '/referrals',
            get: environment.apiBaseUrl + '/referrals/{id}'
        },
        guest: {
            contact_us: environment.apiBaseUrl + '/guest/contact-us',
            newletter_signup: environment.apiBaseUrl + '/guest/newsletter-signup',
            lead_details: environment.apiBaseUrl + '/guest/lead-details',
        },
        lead: {
            base: environment.apiBaseUrl + '/leads',
            get: environment.apiBaseUrl + '/leads/{id}',
            delete: environment.apiBaseUrl + '/leads/{id}/delete'
        },
        note: {
            base: environment.apiBaseUrl + '/notes',
            get: environment.apiBaseUrl + '/notes/{id}',
            delete: environment.apiBaseUrl + '/notes/{id}/delete'
        },
        static: {
            type: environment.apiBaseUrl + '/static/type',
            countries: environment.apiBaseUrl + '/static/countries',
            states: environment.apiBaseUrl + '/static/states',
            cities: environment.apiBaseUrl + '/static/cities',
        },
        student: {
            base: environment.apiBaseUrl + '/students',
            students_journeys: environment.apiBaseUrl + '/students-journeys',
            search: environment.apiBaseUrl + '/students/search',
            partners: environment.apiBaseUrl + '/students/partners',
            stats: environment.apiBaseUrl + '/students/stats',
            get: environment.apiBaseUrl + '/students/{id}',
            general_info: environment.apiBaseUrl + '/students/{id}/general-info',
            education_summary: environment.apiBaseUrl + '/students/{id}/education-summary',
            education_history: environment.apiBaseUrl + '/students/{id}/education-history',
            test_score: environment.apiBaseUrl + '/students/{id}/test-score',
            background_info: environment.apiBaseUrl + '/students/{id}/background-info',
            documents: environment.apiBaseUrl + '/students/{id}/document',
            checklist: environment.apiBaseUrl + '/students/{id}/checklist',
            approve_partner: environment.apiBaseUrl + '/students/{id}/approve',
            revoke_approval_partner: environment.apiBaseUrl + '/students/{id}/revoke-approval',
            partner_company: environment.apiBaseUrl + '/students/{id}/partner-company',
            banking_info: environment.apiBaseUrl + '/students/{id}/banking-info',
            course_applications: environment.apiBaseUrl + '/students/course-applications',
        },
        file: {
            upload: {
                image: environment.apiBaseUrl + '/files/upload/image',
                document: environment.apiBaseUrl + '/files/upload/document'
            },
            download: {
                document: environment.apiBaseUrl + '/files/download/document/{id}'
            }            
        }
        
    },

    pattern: {
        phone: '[0-9]{8,10}',
        //phone: '[0-9]{10}',
        digit: /^[0-9]+$/
    },

    socialAuthProvider: {
        facebook: {
            appId: '278434756336370' // ghelp-dev
        }
    },

    // google clould
    google_cloud_api_key: '',
       
    char_more: '...',
    authTokenKey: 'ombrex_sms_id_token', // // use to store the authenticated user token
    http_header_page_count: 'X-Page-Count',
    http_header_page_size: 'X-Page-Size',
    http_header_total_count: 'X-Total-Count',

    // exclusion list for static data
    exclude_from_course_level: [1205, 1206],
    
    //authUserKey: 'auth_user', // use to store the authenticated user profile
    //appConfig: 'helphunt_app_config'
    key: {
        session: {
            search_course: 'study_offer_search_course_criteria',
            search_institute: 'study_offer_search_institute_criteria'
        }
    }

};
