<div>
    <section id="search-form">
        <form>
            <input type="text" name="search" placeholder="Find Your Program In Canada">
        </form>
    </section>
</div>
<div class="app-container search-container">
    <!-- Seach Filters -->
    <section>
        <article>
            <form [formGroup]="courseFilterForm" (ngSubmit)="onSubmit()">
                <label for="province">Province</label>
                <select [compareWith]="formUtils.compareState" (change)="OnSelectState()"
                    formControlName="instituteState">
                    <option [ngValue]="null">Select</option>
                    <option *ngFor="let state of states" [ngValue]="state">
                        {{state.name}}
                    </option>
                </select>

                <label for="City">City</label>
                <select [compareWith]="formUtils.compareCity" (change)="OnSelectCity()" formControlName="instituteCity">
                    <option [ngValue]="null">Select</option>
                    <option *ngFor="let city of cities" [ngValue]="city">
                        {{city.name}}
                    </option>
                </select>

                <label for="Institute name">Institute name</label>
                <select formControlName="instituteName">
                    <option [ngValue]="null">Select</option>
                    <option *ngFor="let institute of filteredInstituteSummaries" [ngValue]="institute.name">
                        {{institute.name}}
                    </option>
                </select>
                <!-- <input formControlName="instituteName"
                    [typeahead]="filteredInstituteSummaries"
                    typeaheadOptionField="name"
                    [isAnimated]="true"> -->

                <label for="Program Level">Program Level</label>
                <select [compareWith]="formUtils.compareStaticData" formControlName="courseLevel">
                    <option [ngValue]="null">Select</option>
                    <option *ngFor="let courseLevel of courseLevels" [ngValue]="courseLevel">
                        {{courseLevel.value}}
                    </option>
                </select>

                <label for="Discipline">Discipline</label>
                <select [compareWith]="formUtils.compareStaticData" formControlName="discipline">
                    <option [ngValue]="null">Select</option>
                    <option *ngFor="let courseDiscipline of courseDisciplines" [ngValue]="courseDiscipline">
                        {{courseDiscipline.value}}
                    </option>
                </select>

                <label for="Intake">Intake</label>
                <select formControlName="courseStartDate">
                    <option [ngValue]="null">Select</option>
                    <option *ngFor="let intakeDate of intakeDates" [ngValue]="intakeDate.startDate">
                        {{intakeDate.formattedStartDate}}
                    </option>
                </select>

                <label for="Your current location">Your current location</label>
                <select formControlName="studentLocation">
                    <option [ngValue]="null">Select</option>
                    <option value="CANADA">In Canada</option>
                    <option value="OUTSIDE">Outside Canada</option>
                </select>

                <div *ngIf="authService.isUserAdmin()">
                    <label for="Institute status">Institute status</label>
                    <select formControlName="instituteStatus">
                        <option [ngValue]="null">Select</option>
                        <option *ngFor="let instituteStatus of staticDataService.getInstituteStatusValues()"
                            [ngValue]="instituteStatus">
                            {{instituteStatus}}
                        </option>
                    </select>
                
                    <label for="Program status">Program status</label>
                    <select formControlName="courseStatus">
                        <option [ngValue]="null">Select</option>
                        <option *ngFor="let courseStatus of staticDataService.getCourseStatusValues()" [ngValue]="courseStatus">
                            {{courseStatus}}
                        </option>
                    </select>
                </div>

                <div class="w-100">
                    <div class="d-flex flex-row justify-content-end">
                        <div>
                            <button class="btn btn-outline-danger" type="button" (click)="onClearFilters()">CLEAR
                                FILTERS </button>
                        </div>
                        <div class="ml-3">
                            <button type="submit" [disabled]="isFormSubmit" class="btn btn-danger ld-ext-right"
                                [ngClass]="{'running':isFormSubmit}">APPLY FILTERS
                                <div class="ld ld-ring ld-spin"></div>
                            </button>
                        </div>
                    </div>
                </div>


            </form>
        </article>
    </section>

    <!-- Search Results -->
    <section id="search-results">
        <article *ngFor="let courseSummary of courseSummaries">
            <div>
                <a [routerLink]="['/institutes',courseSummary.instituteId,utilService.replaceSpaceWithChar(courseSummary.instituteName)]">
                    <img [src]="courseSummary.instituteLogoImageUrl" alt="" width="80" height="">
                </a>
            </div>
            <div>
                <a [routerLink]="['/courses',courseSummary.courseId,utilService.replaceSpaceWithChar(courseSummary.instituteName), utilService.replaceSpaceWithChar(courseSummary.courseTitle)]">
                <h4>{{courseSummary.courseTitle}}</h4></a>
                <p id="c-name">{{courseSummary.instituteName}}</p>
                <p>{{getLimitedCourseDesc(courseSummary.courseDescription)}}</p>
            </div>
            <div>
                <p id="c-loc"><img src="assets/images/location icon-location-icon.svg" alt="loc-icon" width="15"
                        height="15"> {{courseSummary.instituteCity.name}}, {{courseSummary.instituteState.name}}</p>
                <p id="c-type">{{courseSummary.courseLevel.value}}</p>
            </div>
        </article>

        <div class="pagination justify-content-center" *ngIf="courseSummaryTotalCount > courseSearchPageSize">
            <pagination [totalItems]="courseSummaryTotalCount" [itemsPerPage]="courseSearchPageSize"
                [(ngModel)]="currentPage" [rotate]="pageRotate" [maxSize]="pageMaxSize" previousText="&lsaquo;"
                nextText="&rsaquo;" (pageChanged)="pageChanged($event)">
            </pagination>
        </div>

    </section>



    <!-- <section>

   </section> -->

</div>