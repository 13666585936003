import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { Company } from '../model/company.model';
import { UtilService } from './util.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class GuestService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  public sendContactUsEmail(value: any): Observable<any> {
    return this.http.post<any>(AppConstants.endpoint.guest.contact_us, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public newsletterSignup(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', email); 

    return this.http.get<any>(AppConstants.endpoint.guest.newletter_signup, {headers: this.utilService.getJSONHeader(), params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public sendLeadDetailsEmail(value: any): Observable<any> {
    return this.http.post<any>(AppConstants.endpoint.guest.lead_details, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

}
