import { StaticData } from './static-data.model';
import { State } from './state.model';
import { City } from './city.model';

export class CourseFilter {
    // Institute criteria
    instituteId: number;
	instituteCity: City;
    instituteState: State;
    instituteName: string;
    instituteStatus: string;
	
    // course criteria
    courseTitle: string;
    courseLevel: StaticData;	
    discipline: StaticData;
    //disciplines: StaticData[];
	duration: StaticData;		
	courseStartDate: string; // intake
    studentLocation: string; // In Canada / Outside Canada   
    
    //courseStartDates: string[];
    minTuitionFees: number;
    maxTuitionFees: number;
    courseStatus: string;
}