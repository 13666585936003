<div class="app-container">
    <section>
        <article>
            <h1>Contact Us</h1>
        </article>
        <article>
            <img src="assets/images/Group 303-processing-fee.png" alt="our-benefits" width="600" height="">
        </article>
    </section>
</div>

<div class="sec-color">
    <div class="container">
        <div class="pt-5 pb-3 heading-txt">We have 4 locations to serve you better contact us at your<br> nearest
            branch, Or fill up the following
            form and we will be in<br> touch with you.
        </div>
    </div>
</div>


<div id="rs-branches" class="rs-branches sec-color pt-100">
    <div class="app-container">
        <div class="abt-title text-center">
            <!-- <div class="sec-title-2 text-center">
                    <h2>CONTACT US</h2>
                </div>     -->
            <div>
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="branches-item">
                            <img src="assets/images/about/australia.png" alt="Australia Flag">
                            <h3>
                                <span>01</span>
                                Canada
                            </h3>
                            <p>
                                Brampton Office<br>Unit 5, 7920 Hurontario Street,
                                Brampton, ON L6Y 0P7
                                <br>Phone: +1844-834-4400
                                <br>WhatsApp: 6478600484
                                <br><span class="valign-center"><i class="material-icons md-18">mail</i><span>admissions&#64;onlineofferletter.com</span></span>

                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="branches-item">
                            <img src="assets/images/about/india.png" alt="China Flag">
                            <h3>
                                <span>02</span>
                                India
                            </h3>
                            <p>
                                Karnal Office<br>
                                Shop No 9 First Floor Nehru Place,
                                Karnal - 132001, Haryana,<br>
                                Phone: +91 94160 34611
                                <br>WhatsApp: 6478600484
                                <br><span class="valign-center"><i class="material-icons md-18">mail</i><span>admissions&#64;onlineofferletter.com</span></span>

                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="branches-item">
                            <img src="assets/images/about/india.png" alt="India Flag">
                            <h3>
                                <span>03</span>
                                India
                            </h3>
                            <p>
                                Kurukshetra Office<br>
                                Opposite New Bus Stand,<br>
                                Kurukshetra, 136118, Haryana<br>
                                Phone: +91 94160 34611
                                <br>WhatsApp: 6478600484
                                <br><span class="valign-center"><i class="material-icons md-18">mail</i><span>admissions&#64;onlineofferletter.com</span></span>

                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="branches-item">
                            <img src="assets/images/about/united-states.png" alt="United States Flag">
                            <h3>
                                <span>04</span>
                                Canada
                            </h3>
                            <p>
                                Calgary Office<br>
                                187, Pantego Close NW,<br>
                                Calgary, AB T3K 0K3,<br>
                                Phone: +1-403-437-4354
                                <br>WhatsApp: 6478600484
                                <br><span class="valign-center"><i class="material-icons md-18">mail</i><span>admissions&#64;onlineofferletter.com</span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Branches End -->
    </div>
</div>

<div class="sec-color">
    <!-- <div class="container"> -->
        <div class="pt-5 d-flex" [ngClass]="layoutService.isDesktop() ? 'flex-row' : 'flex-column'">
            <div class="flex-even">
                <!-- <img class="" src="assets/images/about/post-landing-services.jpg" alt="" class="section-img" /> -->
                <img src="assets/images/Guaranteed Letter of Acceptance.png" alt="our-benefits">
            </div>
            <div class="flex-even">
                <div class="contact-form-section" [ngClass]="layoutService.isDesktop() ? 'cn-form-gutter-d' : 'cn-form-gutter-m'">

                
                <div class="mt-3 section-title text-center txt-opac">Contact Us</div>

                <div *ngIf="apiError">
                    <div *ngIf="apiError.message" class="alert alert-success alert-dismissible fade show error-container" role="alert">
                        <button class="close" (click)="onClickClose()">
                            <i class="material-icons">close</i>
                        </button>
                        <span>{{ apiError.message }}</span>
                    </div>
                </div>

                <form id="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <fieldset>
                        <div class="form-group" [ngClass]="formUtils.hasValidationError(contactForm,'name') ? 'input-invalid' : '' ">
                            <input formControlName="name" class="form-control ml-0" type="text" placeholder="NAME">                            
                        </div>
                        <div *ngIf="formUtils.showValidationHint(contactForm,'name', 'required')" class="input-hint hint-margin">
                            {{ 'message.validation.required' | translate }}
                        </div>

                        <div class="form-group" [ngClass]="formUtils.hasValidationError(contactForm,'email') ? 'input-invalid' : '' ">
                            <input formControlName="email" class="form-control ml-0" type="text" placeholder="EMAIL">
                        </div>
                        <div *ngIf="formUtils.showValidationHint(contactForm,'email', 'required')" class="input-hint hint-margin">
                            {{ 'message.validation.required' | translate }}
                        </div>

                        <div class="form-group">
                            <input formControlName="phoneNumber" class="form-control ml-0" type="text" placeholder="PHONE NUMBER">
                        </div>
                        <div class="form-group" [ngClass]="formUtils.hasValidationError(contactForm,'service') ? 'input-invalid' : '' ">                        
                            <select class="form-control ml-0" formControlName="service">
                                <option [ngValue]="null">CHOOSE SERVICE</option>
                                <option value="Study Permit">Study Permit</option>
                                <option value="Work Permit">Work Permit</option>
                                <option value="Visa Extension">Visa Extension</option>
                                <option value="College Admission / Transfer">College Admission / Transfer</option>
                                <option value="Immigration">Immigration</option>
                                <option value="Pre Landing">Pre Landing</option>
                                <option value="Post Landing">Post Landing</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div *ngIf="formUtils.showValidationHint(contactForm,'service', 'required')" class="input-hint hint-margin">
                            {{ 'message.validation.required' | translate }}
                        </div>
                       
                        <div class="form-group" [ngClass]="formUtils.hasValidationError(contactForm,'message') ? 'input-invalid' : '' ">
                            <textarea formControlName="message" rows="3" class="form-control ml-0" type="text" placeholder="MESSAGE"></textarea>
                        </div>
                        <div *ngIf="formUtils.showValidationHint(contactForm,'message', 'required')" class="input-hint hint-margin">
                            {{ 'message.validation.required' | translate }}
                        </div>

                        <div class="mb-2 text-center">
                            <button type="submit" [disabled]="isFormSubmit"
                                class="btn btn-send ld-ext-right"
                                [ngClass]="{'running':isFormSubmit}">Submit Now
                                <div class="ld ld-ring ld-spin"></div>
                            </button>
                        </div>

                        <!-- <div class="form-group mb-2 text-center">
                            <div class="btn-send ld-ext-right" [ngClass]="{'running':isFormSubmit}" type="submit" value="Submit Now" [disabled]="isFormSubmit">
                            <div class="ld ld-ring ld-spin"></div>
                        </div> -->

                        <!-- <div class="form-group mb-2 text-center">
                            <input class="btn-send" type="submit" value="Submit Now">
                        </div>
                         -->
            
                    </fieldset>
                </form>
            </div>
        </div>
        </div>
    <!-- </div> -->
</div>