import { NgModule } from '@angular/core';
import { SearchProgramRoutingModule } from './search-program-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { SearchProgramComponent } from './search-program/search-program.component';

// this is a shared module which is been incuded in app and course module

@NgModule({
  declarations: [SearchProgramComponent],
  imports: [
    SharedModule,
    SearchProgramRoutingModule
  ],
  exports: [
    SearchProgramComponent
  ]
})
export class SearchProgramModule { }
