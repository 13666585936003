import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';

import { AppConstants } from '../../../app.constants';
import { ApiError } from '../../../model/api-error';
import { CourseSummary } from '../../../model/course-summary.model';
import { CourseFilter } from '../../../model/course-filter.model';
import { InstituteSummary } from '../../../model/institute-summary.model';
import { StaticData } from '../../../model/static-data.model';
import { IntakeDate } from '../../../model/intake-date.model';
import { State } from '../../../model/state.model';
import { City } from '../../../model/city.model';
import { InstituteStatus } from '../../../enums/institute-status.enum';
import { CourseStatus } from '../../../enums/course-status.enum';

import { StaticDataType } from '../../../enums/static-data-type.enum';

import { CourseService } from '../../../service/course.service';
import { InstituteService } from '../../../service/institute.service';
import { AuthService } from '../../../service/auth.service';
import { StaticDataService } from '../../../service/static-data.service';
import { LayoutService } from '../../../service/layout.service';
import { UtilService } from '../../../service/util.service';
import { FormUtilsService } from '../../../service/form-utils.service';
import { LoggerService } from '../../../service/logger.service';

@Component({
  selector: 'app-search-program',
  templateUrl: './search-program.component.html',
  styleUrls: ['./search-program.component.scss']
})
export class SearchProgramComponent implements OnInit {

  apiError: ApiError;
  
  isFormSubmit = false;
  isFormSubmitSuccess = false;
  isLoading = false;

  

  courseFilterForm: UntypedFormGroup;
  // Holder for the course data
  courseSummaries: CourseSummary[];
  //courseId: number;

  // pagination variables
  pageRotate = true; // to center the page no
  pageMaxSize = 5;  // no of visible pages
  courseSummaryPageCount = 1; // assume that we have atleast 1 page available
  courseSummaryTotalCount = 0;
  courseSearchPageSize = 0;
  currentPage = 1; // by default, load the first page
 
  courseLevels: StaticData[];
  courseDurations: StaticData[];
  courseDisciplines: StaticData[];
  states: State[];
  cities: City[];
  intakeDates: IntakeDate[];
  instituteSummaries: InstituteSummary[];
  filteredInstituteSummaries: InstituteSummary[];

  selectedCourseDisciplines: StaticData[];
  courseStartDateList: string[];
  selectedCourseStartDateList: string[];

  rangeSlider = new UntypedFormControl([100, 350]);

  courseDescLimitedLength = 50; // limit the course desc length on course search screen

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private courseService: CourseService,
    private instituteService: InstituteService,
    public staticDataService: StaticDataService,
    public utilService: UtilService,
    private logger: LoggerService,
    public authService: AuthService,
    public layoutService: LayoutService,
    public formUtils: FormUtilsService
  ) { }

  ngOnInit() {
    this.createForm();   
    // put the default status
    this.courseFilterForm.get('instituteStatus').setValue(InstituteStatus[InstituteStatus.ACTIVE]);
    this.courseFilterForm.get('courseStatus').setValue(CourseStatus[CourseStatus.ACTIVE]);
    this.loadStaticDataList(StaticDataType.EDUCATION_LEVEL);  
    this.loadStaticDataList(StaticDataType.COURSE_DURATION);  
    this.loadStaticDataList(StaticDataType.COURSE_DISCIPLINE);
    this.getStates(3);
    this.intakeDates = this.utilService.generateCourseIntakesDateOptions();

    // get the saved couse filter from the session
    let courseFilter: CourseFilter = this.utilService.getSessionStorageObjectData(AppConstants.key.session.search_course);
    if (courseFilter != null) {
      this.courseFilterForm.patchValue(courseFilter);
      // if we have selected a state, load it's cities
      if (courseFilter.instituteState != null) {
        this.getCities(courseFilter.instituteState.id);
      }      
      this.getCourses(courseFilter, this.currentPage);
    } else {
      this.getCourses(new CourseFilter(), this.currentPage);
    }
    this.getInstituteSummaries();   
  }

  createForm() {  
    this.courseFilterForm = this.fb.group({
      instituteState: [null],
      instituteCity: [null],
      instituteName: [null],
      instituteStatus: [null],
      
      courseTitle: [null],
      courseLevel: [null],
      discipline: [null],
      duration: [null],
      courseStartDate: [null],
      studentLocation: [null],
      courseStatus: [null]      
    });
  }

  buildSelectControls(controlName: string, staticDataList: StaticData[]) {
    // create the controls from the given static data list
    let controls = staticDataList.map(c => new UntypedFormControl(false));

    //set the controls value
    /* for (let index in staticDataList) {
      if (this.checkExistingBenefit(staticDataList[index])) {
        controls[index].setValue(true);
      }
    } */
    // add the controls in the form group
    this.courseFilterForm.addControl(controlName, new UntypedFormArray(controls));

    /* this.form = this.fb.group({
      orders: new FormArray(this.controls)
    }); */
  }

  getCourses(courseFilter: CourseFilter, pageNumber: number) {
    this.apiError = null;
    this.isLoading = true;
    // save the course filter in session storage
    this.utilService.setSessionStorageObjectData(AppConstants.key.session.search_course, courseFilter);
    this.courseService.getCourseSummaries(courseFilter, pageNumber)
      .subscribe(
        /* data => {
          this.courseSummaries = data;
          this.logger.debug('Got the courses data : ' + JSON.stringify(this.courseSummaries));
        }, */
        // resp is of type `HttpResponse<CourseSummary[]>`
        resp => {  
          this.isLoading = false;        
          this.courseSummaryPageCount = Number(resp.headers.get(AppConstants.http_header_page_count));
          this.courseSummaryTotalCount = Number(resp.headers.get(AppConstants.http_header_total_count));
          this.courseSearchPageSize = Number(resp.headers.get(AppConstants.http_header_page_size));
          this.courseSummaries = resp.body;
          this.logger.debug('Got the courses data : ' + JSON.stringify(this.courseSummaries.length));
          this.logger.debug('Course summary page count : ' + this.courseSummaryPageCount);
          this.logger.debug('Course summary page size : ' + this.courseSearchPageSize);
          this.logger.debug('Course summary total count : ' + this.courseSummaryTotalCount);
        },
        error => {
          this.isLoading = false;
          this.logger.error('Error while fetching course : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  loadStaticDataList(staticDataType: StaticDataType) {
    this.staticDataService.getStaticDataList(staticDataType).subscribe(
      data => {
        if (StaticDataType.EDUCATION_LEVEL == staticDataType) {
          //this.courseLevels = data;
          this.courseLevels = this.utilService.filterStaticDataById(data, AppConstants.exclude_from_course_level);
        } else if (StaticDataType.COURSE_DURATION == staticDataType) {
          this.courseDurations = data;
        }else if (StaticDataType.COURSE_DISCIPLINE == staticDataType) {
          this.courseDisciplines = data;
          //this.buildSelectControls('disciplines', this.courseDisciplines);
        } 
        this.logger.debug('Got the ' + staticDataType + ': ' + JSON.stringify(data));        
      },
      error => {
        this.logger.error('Loading of ' + staticDataType + ' failed : ' + JSON.stringify(error));
      }      
    );
  }

  

  getStates(countryId: number) {
    this.staticDataService.getStates(countryId, 'true')
      .subscribe(
        data => {
          this.states = data;
          this.logger.debug('Got the states data : ' + JSON.stringify(this.states.length));
        },
        error => {
          this.logger.debug('Error while fetching states : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  getCities(stateId: number) {
    this.staticDataService.getCities(stateId, 'true')
      .subscribe(
        data => {
          this.cities = data;
          this.logger.debug('Got the cities data : ' + JSON.stringify(this.cities.length));
        },
        error => {
          this.logger.debug('Error while fetching cities : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  getInstituteSummaries() {
    this.instituteService.getAllInstituteSummaries()
      .subscribe(
        data => {
          this.instituteSummaries = data;
          this.logger.debug('Got the institutes summaries data : ' + JSON.stringify(this.instituteSummaries.length));
          this.getFilteredInstituteSummaries();
        },
        error => {
          this.logger.debug('Error while fetching institutes : ' + JSON.stringify(error));
          this.apiError = error;
        }
      );
  }

  getFilteredInstituteSummaries() {
    // assign all the institutes to filtered list
    this.filteredInstituteSummaries = this.instituteSummaries;
    // filter  based on state
    if ( this.courseFilterForm.value.instituteState != null) {
      this.filteredInstituteSummaries = this.filteredInstituteSummaries.filter(institute => institute.state.name == this.courseFilterForm.value.instituteState.name);
    }
    // filter  based on city
    if ( this.courseFilterForm.value.instituteCity != null) {
      this.filteredInstituteSummaries = this.filteredInstituteSummaries.filter(institute => institute.city.name == this.courseFilterForm.value.instituteCity.name);
    }    
    
    //this.filteredInstituteSummaries = this.instituteSummaries.filter(institute => institute);
  }

  

  getSelectedValues(formValues: any, staticDataList: StaticData[]): StaticData[] {
    let selectedDuties: StaticData[] = [];
    selectedDuties = formValues
      .map((v, i) => v ? staticDataList[i] : null)
      .filter(v => v !== null);

    return selectedDuties;
  }

  OnSelectDiscipline() {
    this.selectedCourseDisciplines = [];
    for (let value of this.getSelectedValues(this.courseFilterForm.value.disciplines, this.courseDisciplines)) {
      this.selectedCourseDisciplines.push(value);
    }
  }

  onSubmit() {
    //this.courseFilterForm.get('disciplines').setValue(this.selectedCourseDisciplines);
    this.logger.debug('Submitted course search filter : ' + JSON.stringify(this.courseFilterForm.value));
    // on fresh search, set the current page to 1
    this.currentPage = 1;
    this.getCourses(this.courseFilterForm.value, this.currentPage);
  }

  onClearFilters() {
    this.courseFilterForm.reset();
    // on fresh search, set the current page to 1
    this.currentPage = 1;
    this.getCourses(new CourseFilter(), this.currentPage);
  }

  // the method moved to utilService
  /* generateCourseIntakesDateOptions(): IntakeDate[] {
    let dateList: IntakeDate[] = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    
    for(let i=0; i < 12 ; i++) {
      //this.logger.debug('Generated date : ' +  formatDate(new Date(currentYear, currentMonth + i, 1), 'MMM yyyy', 'en_US'));
      let startDate = new Date(currentYear, currentMonth + i, 1);
      let intakeDate = new IntakeDate();
      intakeDate.startDate = formatDate(startDate, 'dd/MM/yyyy', 'en_US');
      intakeDate.formattedStartDate = formatDate(startDate, 'MMM yyyy', 'en_US');
      //dateList.push(formatDate(new Date(currentYear, currentMonth + i, 1), 'MMM yyyy', 'en_US'));
      dateList.push(intakeDate);

    }
    
    return dateList;
  } */

  OnSelectState() {
    this.logger.debug('Selected state : ' + JSON.stringify(this.courseFilterForm.value.instituteState));
    if (this.courseFilterForm.value.instituteState != null) {      
      this.getCities(this.courseFilterForm.value.instituteState.id);
    } else {
      this.cities = [];
    } 
    this.courseFilterForm.get('instituteCity').setValue(null);
    this.courseFilterForm.get('instituteName').setValue(null);
    this.getFilteredInstituteSummaries();   
  }

  OnSelectCity() {   
    this.courseFilterForm.get('instituteName').setValue(null); 
    this.getFilteredInstituteSummaries();   
  }

  getLimitedCourseDesc(value: string) {
    let desc = null;
    if (value != null && value.length > this.courseDescLimitedLength) {
      desc = value.substr(0, this.courseDescLimitedLength) + '...';
    } else {
      desc = value;
    }

    return desc;
  }

  pageChanged(event: any): void {
    // get the current course filter
    let courseFilter: CourseFilter = this.utilService.getSessionStorageObjectData(AppConstants.key.session.search_course);
    this.currentPage = event.page;
    this.logger.debug('Course search result paged changed to : ' + this.currentPage);
    this.getCourses(courseFilter, this.currentPage);    
  }

}
