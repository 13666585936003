import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { AuthGuardService } from './security/auth-guard.service';
import { PageNotFoundComponent } from './public/page-not-found/page-not-found.component';

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    bindToComponentInputs: true
    //relativeLinkResolution: 'legacy''

};

/*
const routes: Routes = [
  //{ path: '', redirectTo: 'auth',  pathMatch: 'full' }, // TO DO; re-check
  {path: "landing-page", component: LandingPageComponent },
  { path: 'home',  component: HomeComponent },
  { path: 'about',  component: AboutComponent },
  { path: 'contact',  component: ContactComponent },
  { path: 'privacy-policy',  component: PrivacyPolicyComponent },
  { path: 'wip',  component: WorkInProgressComponent },
  { path: 'unauthorized',  component: UnauthorizedComponent },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  { path: 'student', loadChildren: () => import('./modules/student/student.module').then(m => m.StudentModule)},
  { path: 'student-profile', loadChildren: () => import('./modules/student-profile/student-profile.module').then(m => m.StudentProfileModule)},
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)},
  { path: 'institutes', loadChildren: () => import('./modules/institute/institute.module').then(m => m.InstituteModule)},
  { path: 'courses', loadChildren: () => import('./modules/course/course.module').then(m => m.CourseModule)},
  { path: 'referrals', loadChildren: () => import('./modules/referral/referral.module').then(m => m.ReferralModule)},
  { path: 'benefits', loadChildren: () => import('./public/benefits/benefits.module').then(m => m.BenefitsModule)},
  { path: 'services', loadChildren: () => import('./public/services/services.module').then(m => m.ServicesModule)},
  { path: 'blog', loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)},
  { path: 'lead', loadChildren: () => import('./modules/lead/lead.module').then(m => m.LeadModule)},
  { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)},
  { path: 'staff', loadChildren: () => import('./modules/staff/staff.module').then(m => m.StaffModule)},
  { path: 'access-management', loadChildren: () => import('./modules/access-management/access-management.module').then(m => m.AccessManagementModule)},
  {path: "**", component: PageNotFoundComponent, canActivate: [AuthGuardService]}
];
*/

const routes: Routes = [
  //{ path: '', redirectTo: 'auth',  pathMatch: 'full' }, // TO DO; re-check
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: "", pathMatch: "full", redirectTo: "admin" },
      { path: ':institueId/:courseId/:courseDateId/student', loadChildren: () => import('./modules/student/student.module').then(m => m.StudentModule) },
      { path: 'student-profile', loadChildren: () => import('./modules/student-profile/student-profile.module').then(m => m.StudentProfileModule) },
      { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule) },
      { path: 'institutes', loadChildren: () => import('./modules/institute/institute.module').then(m => m.InstituteModule) },
      { path: ':institueId/courses', loadChildren: () => import('./modules/course/course.module').then(m => m.CourseModule) },
      { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'staff', loadChildren: () => import('./modules/staff/staff.module').then(m => m.StaffModule) },
      { path: 'access-management', loadChildren: () => import('./modules/access-management/access-management.module').then(m => m.AccessManagementModule) },
    ]
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: "**", component: PageNotFoundComponent, canActivate: [AuthGuardService] }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
