import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { Course } from '../model/course.model';
import { CourseDate, CourseSessionDate } from '../model/course-date.model';
import { CourseEnglishRequirement } from '../model/course-english-requirement.model';
import { CourseSummary } from '../model/course-summary.model';
import { CourseFilter } from '../model/course-filter.model';
import { CourseEligibilityCheckResult } from '../model/course-eligibility-check-result.model';
import { UtilService } from './util.service';
import { ExceptionService } from './exception.service';
import { LoaderEnabled } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  public getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>(AppConstants.endpoint.course.base)
      .pipe(catchError(this.exceptionService.handleError));
  }

  @LoaderEnabled()
  createCourseWithGeneralInfo(payload: any): Observable<Course> {
    return this.http.post<Course>(AppConstants.endpoint.course.base, payload, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getCourseSummaries(courseFilter: CourseFilter, pageNumber: number): Observable<HttpResponse<CourseSummary[]>> {   
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber.toString());

    return this.http.post<CourseSummary[]>(AppConstants.endpoint.course.summaries, courseFilter, 
      {params: params, headers: this.utilService.getJSONHeader(), observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));

    // return this.http.post<CourseSummary[]>(AppConstants.endpoint.course.summaries, courseFilter, this.utilService.getHeaders())
    //   .pipe(catchError(this.exceptionService.handleError));

  }

  @LoaderEnabled()
  getCourseDateDetails(sessionId: number): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.getCourseDateDetails, sessionId);
    return this.http.get<any>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getCourse(courseId: number): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.get, courseId);
    return this.http.get<Course>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

  @LoaderEnabled()
  addCourseSession(courseId: number, payload: CourseSessionDate): Observable<CourseSessionDate> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.date, courseId);
    return this.http.post<CourseSessionDate>(url, payload, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public checkCourseEligibility(courseId: number, userId: number, value: any): Observable<CourseEligibilityCheckResult> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.check_eligibility, courseId);
    let params = new HttpParams();
    if (userId != null) {
      params = params.append('userId', userId.toString()); 
    }    

    return this.http.post<CourseEligibilityCheckResult>(url, value, {headers: this.utilService.getJSONHeader(), params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }


  public updateCourseGeneralInfo(courseId: number, value: any): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.general_info, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateCourseDescription(courseId: number, value: any): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.description, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateCourseAdmissionRequirement(courseId: number, value: any): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.admission_req, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateCourseFeeStructure(courseId: number, value: any): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.fees, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public addCourseEnglishRequirement(courseId: number, value: any): Observable<CourseEnglishRequirement> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.requirement_eng, courseId);
    return this.http.post<CourseEnglishRequirement>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateCourseEnglishRequirement(courseId: number, courseEnglishRequirementId: number, value: any): Observable<CourseEnglishRequirement> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.requirement_eng, courseId);
    url = url + '/' + courseEnglishRequirementId;
    return this.http.put<CourseEnglishRequirement>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteCourseEnglishRequirement(courseId: number, courseEnglishRequirementId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.requirement_eng, courseId);
    url = url + '/' + courseEnglishRequirementId;
    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateCourseAcademicRequirement(courseId: number, value: any): Observable<Course> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.requirement_academic, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public addCourseDate(courseId: number, value: any): Observable<CourseDate> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.date, courseId);
    return this.http.post<CourseDate>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateCourseDate(courseId: number, courseDateId: number, value: any): Observable<CourseDate> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.date, courseId);
    url = url + '/' + courseDateId;
    return this.http.put<CourseDate>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteCourseDate(courseId: number, courseDateId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.date, courseId);
    url = url + '/' + courseDateId;
    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public publishCourse(courseId: number): Observable<Course> {
    let value = '';
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.publish, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteCourse(courseId: number): Observable<Course> {
    let value = '';
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course.delete, courseId);
    return this.http.put<Course>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }


}
