<!-- Error message -->
<div *ngIf="apiError">
    <div *ngIf="apiError.message" class="alert alert-dismissible fade show error-container" [ngClass]="alertClass" role="alert">
        <button class="close" (click)="onClickClose()">
            <i class="material-icons">close</i>
        </button>
        <span>{{ apiError.message }}</span>
        <div *ngIf="apiError.fieldErrors">
            <ul>
                <li *ngFor="let fieldError of apiError.fieldErrors">
                    {{fieldError.message}}
                </li>
            </ul>
        </div>
    </div>
</div>