import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { Referral } from '../model/referral.model';
import { ReferralSummary } from '../model/referral-summary.model';
import { ReferralFilter } from '../model/referral-filter.model';
import { CourseEligibilityCheckResult } from '../model/course-eligibility-check-result.model';
import { UtilService } from './util.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {


  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  public getReferrals(referralFilter: ReferralFilter, pageNumber: number): Observable<HttpResponse<ReferralSummary[]>> {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber.toString());
    if (referralFilter != null) {
      if (referralFilter.userId != null) {
        params = params.append('userId', referralFilter.userId.toString());
      }
      if (referralFilter.partnerId != null) {
        params = params.append('partnerId', referralFilter.partnerId.toString());
      }
      if (referralFilter.referToEmail != null) {
        params = params.append('referToEmail', referralFilter.referToEmail);
      }
      if (referralFilter.referByEmail != null) {
        params = params.append('referByEmail', referralFilter.referByEmail);
      }
      if (referralFilter.paymentStatusId != null) {
        params = params.append('paymentStatusId', referralFilter.paymentStatusId.toString());
      }
      if (referralFilter.referralDate != null) {
        params = params.append('referralDate', referralFilter.referralDate);
      }
      
    }

    return this.http.get<ReferralSummary[]>(AppConstants.endpoint.referral.base, {params: params, observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public createReferral(value: any): Observable<Referral> {
    return this.http.post<Referral>(AppConstants.endpoint.referral.base, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getReferral(referralId: number): Observable<Referral> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.referral.get, referralId);
    return this.http.get<Referral>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteReferral(referralId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.referral.get, referralId);
    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateReferralPaymentDetails(referralId: number, value: any): Observable<Referral> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.referral.get, referralId);
    return this.http.put<Referral>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }
}
