<div class="menu-area menu-sticky" id="nav" [ngClass]="{'sticky' : isStickyMenuVisible}">
    <input #navMenuToggleInput id="nav-menu-toggle" type="checkbox">
    <div class="logo d-flex">
        <i class="send-fill-icon bi bi-send-fill" [ngClass]="isSignInPage ? 'no-cursor' : ''" (click)="onClick()"></i>
        <h1 class="title"  (click)="onClick()" [ngClass]="isSignInPage ? 'no-cursor' : ''">COLLEGE ADMIN</h1>
    </div>

    <div class="d-flex header-menu" *ngIf="!isSignInPage">
        <!-- TO DO-->
        <!--<div>
            <i class="bi bi-bell icon-spacing"></i>
            <i class="bi bi-arrows-angle-expand icon-spacing"></i>
        </div>-->
        <div class="dropdown sign-out-dropdown">
            <a (click)="showSignOutMenu()"
                class="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
                [ngClass]="{'show': showSignOut}" data-bs-toggle="dropdown" aria-expanded="true">
                <i class="bi bi-person-circle icon-spacing"></i>
            </a>
            <ul class="dropdown-menu text-small shadow" [ngClass]="{'show': showSignOut}">
                <li class="d-flex justify-content-center"><i class="bi bi-person-circle icon-spacing"></i></li>
                <li class="d-flex justify-content-center"><strong class="pt-3 pl-3 pr-3 pb-1">{{userProfileDetails?.fullName}}</strong></li>
                <li class="d-flex justify-content-center"><a class="pt-1 pb-1 pl-3 pr-3 dropdown-item">{{userProfileDetails?.email}}</a></li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" (click)="onSignOut()">Sign out</a></li>
            </ul>
        </div>

    </div>
    <label for="nav-menu-toggle" class="icon-burger">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </label>
</div>