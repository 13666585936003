<app-loader></app-loader>
<app-header [isSignInPage]="isSignInPage"></app-header>


<div class="page-container">
  <router-outlet></router-outlet>

  <!--<div class="container-fluid h-100">
    <div class="row flex-nowrap h-100">

      <app-side-bar *ngIf="!(isHomePage || isSignInPage)"></app-side-bar>


      <div class="page-container__body">
        <div class="input-group search-section" *ngIf="!(isHomePage|| isSignInPage) && !isAddPages">
          <input type="search" class="form-control search-section__input" placeholder="Search here..."
            aria-label="Search here..." aria-describedby="button-search">
          <button class="btn search-section__icon" type="button" id="button-search"><i
              class="bi bi-search"></i></button>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>-->
</div>

<!--<app-footer></app-footer>-->