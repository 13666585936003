import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

import { ApiError } from '../../model/api-error';
import { AppConstants } from '../../app.constants';
import { ReferralSummary } from '../../model/referral-summary.model';
import { ConfirmationResponse } from '../../model/confirmation-response.model';
import { StaticDataType } from '../../enums/static-data-type.enum';
import { StaticData } from '../../model/static-data.model';
import { ReferralService } from '../../service/referral.service';
import { StaticDataService } from '../../service/static-data.service';
import { LayoutService } from '../../service/layout.service';
import { AuthService } from '../../service/auth.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { LoggerService } from '../../service/logger.service';
import { ReferralFilter } from 'src/app/model/referral-filter.model';

@Component({
  selector: 'app-referral-list',
  templateUrl: './referral-list.component.html',
  styleUrls: ['./referral-list.component.scss']
})
export class ReferralListComponent implements OnInit {

  apiError: ApiError;

  isFormSubmit = false;

  referralFilterForm: UntypedFormGroup;

  referrals: ReferralSummary[];
  paymentStatuses: StaticData[];

  // pagination variables
  pageRotate = true; // to center the page no - pagination component
  pageMaxSize = 5;  // no of visible pages - pagination component
  pageCount = 1; // assume that we have atleast 1 page available, actual count will be returned from backend
  totalCount = 0; // total no of pages, returned from backend
  pageSize = 0; // configured page size for the view, returned from nackend
  currentPage = 1; // by default, load the first page - keeps the track of the page

  constructor(
    private fb: UntypedFormBuilder,
    private referralService: ReferralService,    
    private staticDataService: StaticDataService,
    public authService: AuthService,
    private logger: LoggerService,
    public layoutService: LayoutService,
    public formUtils: FormUtilsService
  ) { }

  ngOnInit() {
    this.createForm();
    this.loadStaticDataList(StaticDataType.PAYMENT_STATUS);
    // let referalFilter = new ReferralFilter();
    // if (this.authService.isUserStudent()) {
    //   referalFilter.userId = this.authService.getLoggedInUserId();
    // }
    this.getReferrals(this.buildReferralFilter(), this.currentPage);
  }

  createForm() {  
    this.referralFilterForm = this.fb.group({
      referToEmail: [null],
      referByEmail: [null],
      referralDate: [null],
      paymentStatus: [null]      
    });
  }

  loadStaticDataList(staticDataType: StaticDataType) {
    this.staticDataService.getStaticDataList(staticDataType).subscribe(
      data => {
        if (StaticDataType.PAYMENT_STATUS == staticDataType) {
          this.paymentStatuses = data;
        }
        this.logger.debug('Got the ' + staticDataType + ': ' + JSON.stringify(data));        
      },
      error => {
        this.logger.error('Loading of ' + staticDataType + ' failed : ' + JSON.stringify(error));
      }      
    );
  }

  getReferrals(referralFilter: ReferralFilter, pageNumber: number) {
    this.referralService.getReferrals(referralFilter, pageNumber)
      .subscribe(        
        resp => {  
          //this.isLoading = false;        
          this.pageCount = Number(resp.headers.get(AppConstants.http_header_page_count));
          this.totalCount = Number(resp.headers.get(AppConstants.http_header_total_count));
          this.pageSize = Number(resp.headers.get(AppConstants.http_header_page_size));
          this.referrals = resp.body;
          this.logger.debug('Got the user referrals : ' + JSON.stringify(this.referrals.length));          
          this.isFormSubmit = false;
        },
        error => {         
          this.logger.debug('Error while fetching user referrals : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }
      );
  }

  onReferralDateValueChange(value: Date) {
    if (value != null) {
      this.referralFilterForm.get('referralDate').setValue(formatDate(value, 'dd/MM/yyyy', 'en_US'));
      //console.log('Date diff in years : ' + this.calculateDateDiffInYears(value, new Date()));
    } else {
      this.referralFilterForm.get('referralDate').setValue(null);
    }  
  }

  onSubmit() {
    this.apiError = null;
    this.logger.debug('Submitted form value : ' + JSON.stringify(this.referralFilterForm.value));
    
    if (this.referralFilterForm.valid) {
      this.isFormSubmit = true;
      this.getReferrals(this.buildReferralFilter(), this.currentPage);      
      
    } else {
      // show validation error
      this.logger.debug('Submitted form value is not valid');
      this.formUtils.markFormGroupTouched(this.referralFilterForm); 
    }
  }

  buildReferralFilter(): ReferralFilter {
    // create the referral filter
    let referralFilter: ReferralFilter = new ReferralFilter();
    if (this.referralFilterForm.value.paymentStatus != null) {
      referralFilter.paymentStatusId = this.referralFilterForm.value.paymentStatus.id;
    }
    if (this.authService.isUserStudent()) {
      referralFilter.userId = this.authService.getLoggedInUserId();
    } else if (this.authService.isUserPartner() || this.authService.isUserCertifiedPartner()) {
      referralFilter.partnerId = this.authService.getLoggedInUserId();
    }
    referralFilter.referralDate = this.referralFilterForm.value.referralDate;
    referralFilter.referToEmail = this.referralFilterForm.value.referToEmail;
    referralFilter.referByEmail = this.referralFilterForm.value.referByEmail;

    return referralFilter;
  }

  onConfirmationResponse(response: ConfirmationResponse) {
    if (response.isConfirmed) {
      this.logger.debug('User confirmed - deleting the course application');
      this.deleteReferral(response.entityId);
    } else {
      this.logger.debug('User declined - not deleting the course application');
    }
  }

  deleteReferral(referralId: number) {
    this.referralService.deleteReferral(referralId)
      .subscribe(
        data => {
          for (let index in this.referrals) {
            if (this.referrals[index].id == referralId) {
              this.referrals.splice(Number(index), 1);
            }
          }          
          this.logger.debug('Deleted referral successfully : ' + JSON.stringify(data));         
        },
        error => {
          this.logger.debug('Error while deleting referral : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }
      );
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getReferrals(this.buildReferralFilter(), this.currentPage);    
  }

}
