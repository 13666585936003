<div class="container">

    <table class="table app-table table-hover table-fixed table-lock-height">
        <thead>
            <tr>
                <th [class]="col?.width" *ngFor="let col of columnDef">{{col.name}}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="tableData?.length > 0; else: noRecords">
                <tr *ngFor="let row of tableData | paginate : pagingConfig; let i = index">
                    <ng-container *ngFor="let col of columnDef">
                        <!--<th *ngIf="col.isNumberField" [class]="col?.width" scope="row">{{ i +1 }}</th>-->
                        <td  [class]="col?.width" *ngIf="!col.isNumberField" tooltip="{{  row[col.field] ?  row[col.field] : '' }}">
                            <ng-container *ngIf="col?.isHyperLink; else: showText">
                                <!--<button type="button" class="btn btn-link" (click)="onClick(row)">{{ col?.hyperLinkText ? col?.hyperLinkText : row[col.field] }}</button>-->
                                <a class="btn-link hyperlink-text" (click)="onClick(row)">{{ col?.hyperLinkText ? col?.hyperLinkText : row[col.field] }}</a>
                            </ng-container>
                            <ng-template #showText>
                                {{ row[col.field] }}
                            </ng-template>
                        </td>
                    </ng-container>

                </tr>
            </ng-container>
            <ng-template #noRecords>
                <div class="m-1">No Records Found!</div>
            </ng-template>
        </tbody>
    </table>
    <div class="row">
        <div class="d-flex ml-auto">
            <div class="col-auto">
                <label class="control-label" for="noOfRows">No. of Rows</label>
            </div>
            <div class="col-auto">
                <select name="noOfRows" (change)="onTableSizeChange($event)" class="form-select form-select-sm">
                    <option *ngFor="let size of tableSize" [ngValue]="size">
                        {{ size }}
                    </option>
                </select>
            </div>
        </div>
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>