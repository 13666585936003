<!-- <div class="top-header">
    <ul>
        <li><img src="assets/images/location icon-location-icon.svg" alt="location" width="" height=""> Unit 5, 7900 Hurontario St Brampton, ON L6Y 0P6</li>
        <li><a href="tel:1844-834-4400"><img src="assets/images/Vector-phone-red.svg" alt="phone" width="" height=""> 1844-834-4400</a></li>
        <li><a href="mailto:admissions@onlineofferletter.com"><img src="assets/images/mail icon.svg" alt="email" width="" height=""> admissions@onlineofferletter.com</a></li>
        <li>Esteemed Part of <span style="color: red;">Canada Visa Consultants</span></li>
        <li><a href="#" style="color: red;">Login</a></li>
    </ul>
</div> -->
  <!-- <div class="menu-area menu-sticky"  id="nav">
      <input id="nav-menu-toggle" type="checkbox">
      <div class="logo">
          <a href="/"><img src="https://onlineofferletter.com/assets/images/logo-white.png" alt="logo" width="" height=""></a> 
      </div>
      <ul class="menu-list">
          <li><a href="/"><img src="assets/images/Home-home-icon.svg" alt="home" width="" height=""></a></li>
          <li><a href="/about">About us</a></li>
          <li><a href="/benefits">Benefits</a></li>
          <li><a href="/services">Our Services</a></li>
          <li><a href="/referrals">Referrals</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="tel:1844-834-4400"><img src="assets/images/Vector-phone-icon.svg"> 1844-834-4400</a></li>
          <li><a href="/contact" style="background-color: #fff; color: #000; border-radius: 10px;">Apply Now</a></li>
      </ul>
      <label for="nav-menu-toggle" class="icon-burger">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
      </label>
  </div> -->
    <div class="hero">
        <section>
            <div class="typewriter">
                <h1>College Admin</h1>
            </div>
            <h2>College Admission Just a “Click” <span><img src="assets/images/vector-pointer.png" alt="pointer" width="20px"></span>Away</h2>
       

            <button class="learn-more" (click)="OnClickGetStarted()">Get Started Now</button>
     </section>
     <section>
        <img src="assets/images/vector-hero.png" width="80%">
     </section>
  
    </div>

    <div class="section">
        <div class="feat">
            <section>
                <article>
                    <img src="assets/images/Group 272.png" alt="" width="" height="">
                    <h2>100+</h2><span>Institutes</span>
                </article>
                <article>
                    <img src="assets/images/Group 273.png" alt="" width="" height="">
                    <h2>60+</h2><span>Streams</span>
                </article>
                <article>
                    <img src="assets/images/Group 275.png" alt="" width="" height="">
                    <h2>1000+</h2><span>Courses</span>
                </article>
                <article>
                    <img src="assets/images/Group 274.png" alt="" width="" height="">
                    <h2>5000+</h2><span>Scholarships</span>
                </article>

            </section>
            <section>
                <article>
                    <img src="assets/images/Group 276.png" alt="" width="" height="">
                    <h2>10000+</h2><span>Offer Letters</span>
                </article>
                <article>
                    <img src="assets/images/Group 277.png" alt="" width="" height="">
                    <h2>6000+</h2><span>Study Visa Approved</span>
                </article>
                <article>
                    <img src="assets/images/Group 278.png" alt="" width="" height="">
                    <h2>4000+</h2><span>Study Permit Extensions</span>
                </article>
                <article>
                    <img src="assets/images/Group 279.png" alt="" width="" height="">
                    <h2>3000+</h2><span>PGWP Approved</span>
                </article>

            </section>

        </div>
    </div>

    <div class="offers">
        <h2>Our Offers</h2>
        <section>
            <article>
                <img src="assets/images/Group 299-apply.png" alt="" width="450" height="">
                <p>Apply & Get Guaranteed
                    CashBack<br> Bonus Upto $250
                    On Admission</p>
                <!-- <button class="learn-more"><a href="#">Learn More</a></button> -->
                <a [routerLink]="['/benefits/cashback']"><button class="learn-more">Learn More</button></a>                
            </article>
            <article>
                <img src="assets/images/Group 300-refer.png" alt="" width="450" height="">
                <p>Earn A Referral Reward Of
                    Upto $700<br> For Each Student
                    You Refer</p>
                <!-- <button class="learn-more"><a href="#">Learn More</a></button> -->
                <a [routerLink]="['/benefits/referral-reward']"><button class="learn-more">Learn More</button></a>
            </article>
        </section>
        <section>
            <article>
                <img src="assets/images/Group 303-processing-fee.png" alt="" width="450" height="">
                <p>No
                    File Processing
                    Charges</p>
                    <a [routerLink]="['/benefits/no-processing-fees']"><button class="learn-more">Learn More</button></a>
            </article>
            <article>
                <img src="assets/images/Group 302off.png" alt="" width="450" height="">
                <p>50% off
                    on SPE and
                    PGWP application</p>
                    <a [routerLink]="['/benefits/immigrations']"><button class="learn-more">Learn More</button></a>
            </article>
        </section>

    </div>
    <div class="how-it-works">
        <h2>How It Works?</h2>
    
        <section>
            <article>
                <div id="hiw-xyz">
                <div class="valign-center"><span class="pr-3"><i class="material-icons md-primary">double_arrow</i></span>Begin by Finding Program and School That Fit Best</div>
                <div class="step-align"><i class="material-icons">south</i></div>
                <div class="valign-center"><span class="pr-3"><i class="material-icons md-primary">double_arrow</i></span>Submit Your Complete Application</div>
                <div class="step-align"><i class="material-icons">south</i></div>
                <div class="valign-center"><span class="pr-3"><i class="material-icons md-primary">double_arrow</i></span>Letter of Acceptance</div>
                <div class="step-align"><i class="material-icons">south</i></div>
                <div class="valign-center"><span class="pr-3"><i class="material-icons md-primary">double_arrow</i></span>Start the Visa Process</div>
                <div class="step-align"><i class="material-icons">south</i></div>
                <div class="valign-center"><span class="pr-3"><i class="material-icons md-primary">double_arrow</i></span>Book Your Plane Ticket and Get Set Go!</div>
            </div>
            </article>
           

            <article>
            
            <div id="hiw-abc" class="cursor-pointer mt-3 mb-3" (click)="openModal(howitworkstemplate)">
                <img src="assets/images/how-it-works/Group 2.2@2x.png" id="hiw-vid" class="hiw-video-full" >
            </div>
        </article>

        </section>

        <ng-template #howitworkstemplate>
            <div class="modal-header">
              <!-- <h4 class="modal-title pull-left">Modal</h4> -->
              <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/hIhRc8j7zRQ"
                    width="100%" height="500" allowfullscreen="" frameborder="0"></iframe>
            </div>
          </ng-template>
    
        <section><button class="learn-more" (click)="OnClickGetStarted()">Get Started</button></section>
        <!-- <button class="learn-more" (click)="OnClickGetStarted()">Get Started</button> -->
    
    </div>

    <div class="testimonials">
        <h2>What Our Students Say About Us</h2>
        <img src="assets/images/pencil-scrawl.png" alt="pencil-scroll" width="100%" height="">
                    <!--Testimonial Slider-->
                    <div class="owl-carousel owl-theme">
                        <div class="item"><article style="background: rgba(65, 98, 151, 0.23); padding: 30px; border-radius: 10px;">                            
                            <p style="font-size: 20px;">Got my offer letter in 2 days. Excellent Service</p>
                            <p>I just want to thank College Admin to help me with getting an
                                offer letter within 2 days as promised.
                                They took care of everything once I provided them my documents. I was very stressed due
                                to my gap period after studies,
                                but they gave me a relief by providing me the offer letter in such a short time frame.
                                Recommend this portal to everyone
                                with complexities in their profile.</p>                        
                        </article></div>
                        <div class="item"><article style="background: #416297; padding: 30px; border-radius: 10px; color: #FFFFFF">
                            <p style="font-size: 20px;">Excellent work! Got my LOA/Offer letter in no time</p>
                            <p>I want to recommend College Admin to everybody who is in need
                                of admission in another college.
                                I am already in Canada and had to change college due to some unavoidable circumstances.
                                Went on this site, explained them my profile and they got my offer letter in a public
                                college in no time.
                                I am very happy with their service.</p>
                        
                        </article></div>
                        <div class="item"><article style="background: rgba(65, 98, 151, 0.23); padding: 30px; border-radius: 10px;">
                            <p style="font-size: 20px;">Got my college transferred in no time</p>
                            <p>College Admin is the solution for all student immigration
                                related issues
                                that one face. <br>They helped me to get an unconditional
                                offer letter in less than 48 hours when I explained them that I want to change college
                                and program as I was not performing well in the current program. <br><br><br></p>
                        
                        </article></div>
                        <div class="item"><article style="background: #416297; padding: 30px; border-radius: 10px; color: #FFFFFF">
                            <p style="font-size: 20px;">Got admission in desired college</p>
                            <p>I want to thank the staff behind College Admin portal for their
                                exceptional and very pleasant service.
                                I wanted to get admission in Canadore college and they helped me to get the offer letter
                                within 2 days.
                                <br>I highly suggest people to go through from this website before thinking about going
                                to any agent. <br><br></p>
                        
                        </article></div>

                        <div class="item"><article style="background: rgba(65, 98, 151, 0.23); padding: 30px; border-radius: 10px;">
                            <p style="font-size: 20px;">Very much satisfied with the service</p>
                            <p>I have applied my offer letter through them and have got it within
                                the time frame given. Very much satisfied with the service as I am an average
                                student with average IELTS scores and still got admission in a public college.
                                Will be sticking with them for other services until I get my PR.</p>
                        
                        </article></div>

                        <div class="item"><article style="background: #416297; padding: 30px; border-radius: 10px; color: #FFFFFF">
                            <p style="font-size: 20px;">Got transfer from private to public college</p>
                            <p>I went on this portal when I got to know that the college
                                where I am studying wouldn’t fetch me Post-grad work permit. Got proper guidance
                                with regards to steps that the staff will follow to get me admission in a public
                                college and now I got my offer letter and m very much satisfied with their service.</p>
                        
                        </article></div>

                        <div class="item"><article style="background: rgba(65, 98, 151, 0.23); padding: 30px; border-radius: 10px;">
                            <p style="font-size: 20px;">Had an excellent experience with getting admission</p>
                            <p>I got an amazing service from them.
                                Discussed my case with them and they got me an offer letter within 2 days.
                                I am amazed with their highly professional and dedicated service.
                                I suggest everyone to contact them for to sort their immigration related issues.</p>
                        
                        </article></div>

                        <div class="item"><article style="background: #416297; padding: 30px; border-radius: 10px; color: #FFFFFF">
                            <p style="font-size: 20px;">Contact them if needed admission in a Canadian college in no time</p>
                            <p>I simply answered some of their questions,
                                provided them the asked documents. They treat you very professionally
                                and provide very individualized service. They took care of everything.
                                I asked for admission in Canadore college and they got it in no time.</p>
                        
                        </article></div>
                    </div>
    </div>
    
    