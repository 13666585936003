import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-busy-loading',
  templateUrl: './busy-loading.component.html',
  styleUrls: ['./busy-loading.component.scss']
})
export class BusyLoadingComponent {
  @Input()
  public busy: boolean;

  constructor() { }

}
