<!-- <img [src]="buildProfileImageLink()" class="img-circle" [width]="width" [height]="height"> -->
<!-- <img [src]="imageUrl" class="rounded-circle" [width]="width" [height]="height"> -->

<app-api-error [apiError]="apiError"></app-api-error>
<div class="d-flex flex-column align-items-center">
  <div>
    <img [src]="imageUrl" [ngClass]="{'rounded-circle': isRounded}" [width]="width" [height]="height">
    <!-- <div class="oval rounded-circle"><i class="material-icons md-dark">add_a_photo</i></div> -->
    <input style="display: none" type="file" accept=".jpg,.jpeg,.png,.gif" (change)="onFileChanged($event)" #fileInput>
  </div>

  <div *ngIf="isEdit" class="btn-img-edit" [ngClass]="{'rounded-circle': isRounded}">
    <!-- <a class="btn-icon cursor-hand" (click)="fileInput.click()"><i class="material-icons md-dark">add_a_photo</i></a> -->
    <a class="btn-icon cursor-hand ld-ext-right" [ngClass]="{'running':isFormSubmit}" (click)="fileInput.click()"><i class="material-icons md-dark">add_a_photo</i><div class="ld ld-ring ld-spin"></div></a>    
  </div>
</div>