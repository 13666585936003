import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '../service/auth.service';
import { LoggerService } from '../service/logger.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  URL_LOGIN = '/auth/signin';
  URL_UNAUTHORIZED = '/unauthorized';

  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: LoggerService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //this.logger.debug('canActivate : ' + route.url + ':: return url: ' + state.url);
    return this.canActivateRoute(route, state);
  }

  // private canActivateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   let isUserLoggedIn = this.authService.isUserLoggedIn();
  //   if (!this.authService.isUserLoggedIn()) {
  //     // not logged in so redirect to login page with the return url and return false
  //     this.router.navigate([this.URL_LOGIN], { queryParams: { returnUrl: state.url }});
  //     return false;

  //   }
  //   return true;
  // }

  private canActivateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isUserLoggedIn = this.authService.isUserLoggedIn();
    if (!isUserLoggedIn) {
      // not logged in so redirect to login page with the return url and return false
      this.router.navigate([this.URL_LOGIN], { queryParams: { returnUrl: state.url }});
      return false;
    }
    
    if (isUserLoggedIn && route.data.roles && !route.data.roles.includes(this.authService.getLoggedInUserRole())) {
      console.log('User role : ' + this.authService.getLoggedInUserRole());
      console.log('route roles : ' + JSON.stringify(route.data.roles));
      // logged in but don't have the required roles
      this.router.navigate([this.URL_UNAUTHORIZED], { queryParams: { accessedUrl: state.url }});
      return false;
    }
    return true;
  }

  // canActivate(): boolean {
  //   if (!this.authService.isUserLoggedIn()) {
  //     this.router.navigateByUrl(this.URL_LOGIN);
  //     return false;
  //   }
  //   return true;
  // }


  /* private canActivateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if ((!route.data.roles && user)
      || (route.data.roles && !user)
      || (user && route.data.roles && !route.data.roles.includes(user.role)) || state.url === '/') {
      if (user) {
        if (user.role === Role.USER_MANAGER) {
          this.router.navigate(['/admin/users']);
        } else {
          this.router.navigate(['/glee']);
        }
      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
    return true;
  } */

}
