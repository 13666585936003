<button *ngIf="btnType == 'icon'" type="button" class="btn-icon valign-center" (click)="openModal(template)">
	<i class="material-icons md-primary md-18">{{btnIcon}}</i></button>

<button *ngIf="btnType == 'button'" type="button" [disabled]="isFormSubmit"
	class="btn btn-danger ld-ext-right"
	(click)="openModal(template)"
	[ngClass]="{'running':isFormSubmit}">{{btnText}}
	<div class="ld ld-ring ld-spin"></div>
</button>

<ng-template #template>
	<div class="modal-header conf-header">
		<h4 class="modal-title pull-left">Confirm</h4>
		<!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button> -->
	</div>
	<div class="modal-body">
		<div class="valign-center"><i class="material-icons md-primary">warning</i><span class="ml-3 cnt-txt cnt-txt-lg txt-bold txt-opac">Do you want to confirm?</span></div>
		<div class="text-right">
			<button type="button" class="btn btn-outline-danger" (click)="decline()">No</button>
			<button type="button" class="ml-2 btn btn-danger" (click)="confirm()">Yes</button>
		</div>
	</div>
</ng-template>