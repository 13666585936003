import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FileService } from '../../service/file.service';
import { LoggerService } from '../../service/logger.service';
import { FileUploadResponse } from '../../model/file-upload-response.model';
import { ApiError } from '../../model/api-error';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input()
  public imageUrl: string;
  @Input()
  public height: number;
  @Input()
  public width: number;
  @Input()
  public isEdit = false;
  @Input()
  public isRounded = true;

  @Output()
  uploadComplete = new EventEmitter<FileUploadResponse>();

  selectedFile: File = null;
  apiError: ApiError;
  isFormSubmit = false;
  maxFileSize = 9900000;  // in bytes ( 10 MB)

  //profileImageLink: string;

  constructor(
    private fileService: FileService,
    private logger: LoggerService
  ) { }

  ngOnInit() {
    if (!this.imageUrl) {
      //this.imageUrl = 'assets/img/man-icon.png';
      this.imageUrl = 'assets/img/insert_photo-24px.svg';
      
    }
  }

  onFileChanged(event) {
    //console.debug('File upload event : ' + JSON.stringify(event));
    this.apiError = null;
    this.selectedFile = event.target.files[0];
    console.log('Selected file : ' + JSON.stringify(this.selectedFile.name));
    console.log('Selected file size : ' + this.selectedFile.size);
    if (this.selectedFile.size < this.maxFileSize) {
      this.uploadImage(this.selectedFile);
    } else {
      this.apiError = new ApiError();
      this.apiError.message = 'File size too big, should be <= 2 MB';
    }  
  }

  uploadImage(file: File) {   
    this.isFormSubmit = true;
    this.fileService.uploadImage(file).subscribe(
      data => {
        this.logger.error('Image file upload successfull : ' + JSON.stringify(data));
        this.emitResponse(data);
        this.isFormSubmit = false;       
      },
      error => {
        this.logger.error('Image file upload failed : ' + JSON.stringify(error));
        this.apiError = error;
        this.isFormSubmit = false;
      }  
    );
  }

  emitResponse(imageUploadResponse: FileUploadResponse) {
    this.uploadComplete.emit(imageUploadResponse);
  }

  /* public buildProfileImageLink(): string {
      if (this.profileImage != null) {
        this.profileImageLink = this.config.apiBaseEndpoint +
            this.config.imageStoreEndpoint + '/' + this.profileImage.id;
    } else if (this.profileImageId) {
        this.profileImageLink = this.config.apiBaseEndpoint +
            this.config.imageStoreEndpoint + '/' + this.profileImageId;
    } else {
        this.profileImageLink = 'assets/images/man-icon.png';
    }
    return this.profileImageLink;
  } */

}
