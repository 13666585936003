import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { Institute } from '../model/institute.model';
import { InstituteSummary } from '../model/institute-summary.model';
import { Course } from '../model/course.model';
import { InstituteFilter } from '../model/institute-filter.model';
import { InstituteBenefitContainer } from '../model/institute-benefit-container.model';
import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';
import { InstituteStatus } from '../enums/institute-status.enum';
import { LoaderEnabled } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InstituteService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  @LoaderEnabled()
  getInstitutes(filter: InstituteFilter, defaultParams: any): Observable<HttpResponse<Institute[]>> {
    let params = new HttpParams();
    if (filter != null) {
      if (filter.stateId != null) {
        params = params.append('stateId', filter.stateId.toString());
      }
      if (filter.cityId != null) {
        params = params.append('cityId', filter.cityId.toString());
      }
      if (filter.status != null) {
        params = params.append('status', filter.status);
      }
    }

    params = params.append('pageNumber', defaultParams.currentPage.toString());
    params = params.append('pageSize', defaultParams.itemsPerPage.toString());

    return this.http.get<Institute[]>(AppConstants.endpoint.institute.base, { params: params, observe: 'response' })
      .pipe(catchError(this.exceptionService.handleError));

  }

  public getAllInstituteSummaries(): Observable<InstituteSummary[]> {
    return this.http.get<InstituteSummary[]>(AppConstants.endpoint.institute.summaries)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getInstitute(instituteId: number): Observable<Institute> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.get, instituteId);
    return this.http.get<Institute>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

  @LoaderEnabled()
  createInstituteWithGeneralInfo(payload: any): Observable<Institute> {
    return this.http.post<Institute>(AppConstants.endpoint.institute.base, payload, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateInstituteGeneralInfo(instituteId: number, value: any): Observable<Institute> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.general_info, instituteId);
    return this.http.put<Institute>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateInstituteAbout(instituteId: number, value: any): Observable<Institute> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.about, instituteId);
    return this.http.put<Institute>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateInstituteBenefits(instituteId: number, instituteBenefitContainer: InstituteBenefitContainer): Observable<Institute> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.benefits, instituteId);
    return this.http.put<Institute>(url, instituteBenefitContainer, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateInstituteImages(instituteId: number, value: any): Observable<Institute> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.images, instituteId);
    return this.http.put<Institute>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public publishInstitute(instituteId: number): Observable<Institute> {
    let value = '';
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.publish, instituteId);
    return this.http.put<Institute>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteInstitute(instituteId: number): Observable<Institute> {
    let value = '';
    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.delete, instituteId);
    return this.http.put<Institute>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  @LoaderEnabled()
  getCoursesOfInstitute(instituteId: number, defaultParams: any): Observable<Course[]> {
    let params = new HttpParams();
    params = params.append('pageNumber', defaultParams.currentPage.toString());
    params = params.append('pageSize', defaultParams.itemsPerPage.toString());

    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.courses, instituteId);
    return this.http.get<Course[]>(url, { params: params })
      .pipe(catchError(this.exceptionService.handleError));
  }

  getCoursesOfInstituteOld(instituteId: number): Observable<Course[]> {

    let url = this.utilService.prepareUrl(AppConstants.endpoint.institute.courses, instituteId);
    return this.http.get<Course[]>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

}
