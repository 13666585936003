import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { StaticData } from '../model/static-data.model';
import { State } from '../model/state.model';
import { City } from '../model/city.model';
import { Country } from '../model/country.model';
import { StaticDataType } from '../enums/static-data-type.enum';
import { Gender } from '../enums/gender.enum';
import { VisaType } from '../enums/visa-type.enum';
import { InstituteStatus } from '../enums/institute-status.enum';
import { CourseStatus } from '../enums/course-status.enum';
import { MaritalStatus } from '../enums/marital-status.enum';
import { CountrySortType } from '../enums/country-sort-type.enum';
import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';
import { LoaderEnabled } from './loader.service';



@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  @LoaderEnabled()
  getStaticDataList(staticDataType: StaticDataType): Observable<StaticData[]> {
    let params = new HttpParams();
    params = params.append('staticDataType', StaticDataType[staticDataType]);
    return this.http.get<StaticData[]>(AppConstants.endpoint.static.type, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getCountries(countrySortType: CountrySortType): Observable<Country[]> {  
    let params = new HttpParams();
    params = params.append('countrySortType', CountrySortType[countrySortType]);  
    return this.http.get<Country[]>(AppConstants.endpoint.static.countries, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getStates(countryId: number, applyInstituteFilter: string): Observable<State[]> {  
    let params = new HttpParams();
    params = params.append('countryId', countryId.toString());  
    params = params.append('applyInstituteFilter', applyInstituteFilter);
    return this.http.get<State[]>(AppConstants.endpoint.static.states, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  /**
   * Get the cities
   * @param stateId 
   * @param applyInstituteFilter Boolean parameter - true/false
   */
  public getCities(stateId: number, applyInstituteFilter: string): Observable<City[]> {  
    let params = new HttpParams();
    params = params.append('stateId', stateId.toString()); 
    params = params.append('applyInstituteFilter', applyInstituteFilter);  
    return this.http.get<City[]>(AppConstants.endpoint.static.cities, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getGenderValues() {
    return Object.keys(Gender).filter(k => typeof Gender[k as any] === "number");
  }

  public getMaritalStatusValues() {
    return Object.keys(MaritalStatus).filter(k => typeof MaritalStatus[k as any] === "number");
  }

  public getVisaTypeValues() {
    return Object.keys(VisaType).filter(k => typeof VisaType[k as any] === "number");
  }

  public getInstituteStatusValues() {
    return Object.keys(InstituteStatus).filter(k => typeof InstituteStatus[k as any] === "number");
  }

  public getCourseStatusValues() {
    return Object.keys(CourseStatus).filter(k => typeof CourseStatus[k as any] === "number");
  }
}
