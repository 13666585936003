import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { Attachment } from '../model/attachment.model';
import { AttachmentFilter } from '../model/attachment-filter.model';
import { UtilService } from './util.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  public getAttachments(filter: AttachmentFilter, pageNumber: number): Observable<HttpResponse<Attachment[]>> {
    let params = new HttpParams();
    if (filter != null) {
      if (filter.entityType != null) {
        params = params.append('entityType', filter.entityType);
      }
      if (filter.entityId != null) {
        params = params.append('entityId', filter.entityId.toString());
      }
     
    }
    params = params.append('pageNumber', pageNumber.toString());

    return this.http.get<Attachment[]>(AppConstants.endpoint.attachment.base, {params: params, observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public addAttachment(value: any): Observable<Attachment> {
    return this.http.post<Attachment>(AppConstants.endpoint.attachment.base, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

}
