import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiError } from '../../model/api-error';

import { AppConstants } from '../../app.constants';

import { AuthService } from '../../service/auth.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { UtilService } from '../../service/util.service';
import { AttachmentService } from '../../service/attachment.service';
import { FileService } from '../../service/file.service';
import { LayoutService } from '../../service/layout.service';
import { LoggerService } from '../../service/logger.service';
import { Attachment } from 'src/app/model/attachment.model';
import { AttachmentFilter } from 'src/app/model/attachment-filter.model';
import { Document } from 'src/app/model/document.model';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  @Input()
  entityType: string;

  @Input()
  entityId: number;

  apiError: ApiError;
  isFormSubmit = false;

  attachmentForm: UntypedFormGroup;
  attachments: Attachment[];
  selectedFile: File;
  maxFileSize = 9100000;  // in bytes ( 9.0 MB)

  // pagination variables
  pageRotate = true; // to center the page no - pagination component
  pageMaxSize = 5;  // no of visible pages - pagination component
  pageCount = 1; // assume that we have atleast 1 page available, actual count will be returned from backend
  totalCount = 0; // total no of pages, returned from backend
  pageSize = 0; // configured page size for the view, returned from nackend
  currentPage = 1; // by default, load the first page - keeps the track of the page


  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,    
    private attachmentService: AttachmentService,
    private fileService: FileService,
    public layoutService: LayoutService,
    private logger: LoggerService,
    public authService: AuthService,
    public utilService: UtilService,
    public formUtils: FormUtilsService 
  ) { }

  ngOnInit() {
    this.createForm();
    this.getAttachments(this.currentPage);
  }

  createForm() {  
    this.attachmentForm = this.fb.group({      
      documentType: [null, [Validators.required]],
      documentName: [null, [Validators.required]],
      description: [null]
    });

    
  }

  getAttachments(pageNumber: number) {
    let attachmentFilter = new AttachmentFilter();
    attachmentFilter.entityType = this.entityType;
    attachmentFilter.entityId = this.entityId;

    this.attachmentService.getAttachments(attachmentFilter, pageNumber).subscribe(        
        resp => {  
          //this.isLoading = false;        
          this.pageCount = Number(resp.headers.get(AppConstants.http_header_page_count));
          this.totalCount = Number(resp.headers.get(AppConstants.http_header_total_count));
          this.pageSize = Number(resp.headers.get(AppConstants.http_header_page_size));
          this.attachments = resp.body;
          this.logger.debug('Got the attachments data : ' + JSON.stringify(this.attachments.length));          
          this.isFormSubmit = false;
        },
        error => {         
          this.logger.debug('Error while fetching attachments : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }
      );
  }

    

  onSubmit() {
    this.apiError = null;    
    this.logger.debug('Submitted form value : ' + JSON.stringify(this.attachmentForm.value));    
    if (this.attachmentForm.valid) {
      this.isFormSubmit = true;
      
      this.fileService.uploadDocument(this.selectedFile).subscribe(
        data => {
          this.logger.error('file upload successfull : ' + JSON.stringify(data));
          // on successful upload, save the document info
          this.addAttachment(data);          
        },
        error => {
          this.logger.error('file upload failed : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;          
        }  
      );
      
    } else {
      // show validation error
      this.logger.debug('Submitted form value is not valid');
      this.formUtils.markFormGroupTouched(this.attachmentForm); 
    } 
  }

  /**
   * Add the attachment with the successfully uploaded document details
   */
  addAttachment(document: Document) {
   
    let attachment: Attachment = new Attachment();
    attachment.entityId = this.entityId;
    attachment.entityType = this.entityType;
    attachment.documentType = this.attachmentForm.value.documentType;
    attachment.document = document;

    this.attachmentService.addAttachment(attachment).subscribe(
      data => {
        this.logger.debug('Successfully added attachment : ' + JSON.stringify(data));
        this.apiError = null;
        this.isFormSubmit = false;
        // on successfully addition, reset the form
        this.selectedFile = null;
        this.attachmentForm.reset();
        // refresh the attachments list
        this.getAttachments(this.currentPage);
      },
      error => {
        this.logger.error('Add attachment failed : ' + JSON.stringify(error));
        this.apiError = error;
        this.isFormSubmit = false;
      }      
    );
  }

  onFileChanged(event: any) {    
    this.apiError = null;
    this.selectedFile = event.target.files[0];
    this.logger.debug('Selected file : ' + JSON.stringify(this.selectedFile.name));
    this.logger.debug('Selected file size : ' + this.selectedFile.size);
    if (this.selectedFile.size < this.maxFileSize) {
      this.logger.debug('Uploading file');
      this.attachmentForm.get('documentName').setValue(this.selectedFile.name);
      //this.uploadCertificate(event.target.files[0],index, formGroup);      
    } else {
      this.apiError = new ApiError();
      this.apiError.message = 'File size too big, should be <= 10 MB';
    }  
  }

  onDownloadDocument(studentDocumentId: number) {
    this.fileService.downloadDocument(studentDocumentId)
      .subscribe(
        data => {          
          this.logger.debug('Download of document complete: ' +  studentDocumentId);
          const url= window.URL.createObjectURL(data);
          window.open(url);
        },
        error => {
          this.logger.debug('Error while dowloading document');
          this.apiError = error;          
        }
      );
  }

  onDeleteDocument(studentDocumentId: number) {
    // this.attachmentService.deleteAttachment(this.student.id, studentDocumentId)
    //   .subscribe(
    //     data => {
    //       for (let index in this.student.studentDocuments) {
    //         if (this.student.studentDocuments[index].id == studentDocumentId) {
    //           this.student.studentDocuments.splice(Number(index), 1);
    //         }
    //       }          
    //       this.logger.debug('Deleted student document successfully : ' + JSON.stringify(data));         
    //     },
    //     error => {
    //       this.logger.debug('Error while deleting student document : ' + JSON.stringify(error));
    //       this.apiError = error;
    //       this.isFormSubmit = false;
    //     }
    //   );
  }


}
