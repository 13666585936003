import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface PagingConfig {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
};

@Component({
    selector: "app-table",
    templateUrl: "./table-wrapper.component.html",
    styleUrls: ["./table-wrapper.component.scss"]
})
export class TableWrapperComponent implements OnInit {

    currentPage: number = 1;
    itemsPerPage: number = 5;
    totalItems: number = 0;
    tableSize: number[] = [5, 10, 15, 20];
    pagingConfig: PagingConfig = {} as PagingConfig;

    @Input() tableData: any[] = [];
    @Input() columnDef: any[] = [];
    @Input() set defaultParams(val: any) {
        this.pagingConfig = {
            itemsPerPage: val ? val.itemsPerPage : this.itemsPerPage,
            currentPage: val ? val.currentPage : this.currentPage,
            totalItems: val ? val.totalItems : this.totalItems
        };
    };

    @Output() fetchData: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitRowClick: EventEmitter<any> = new EventEmitter<any>();

    constructor() {

    }

    ngOnInit(): void {
        this.pagingConfig = {
            itemsPerPage: this.defaultParams ? this.defaultParams.itemsPerPage : this.itemsPerPage,
            currentPage: this.defaultParams ? this.defaultParams.currentPage : this.currentPage,
            totalItems: this.defaultParams ? this.defaultParams.totalItems : this.totalItems
        }
    }

    onTableDataChange(event: any) {
        this.pagingConfig.currentPage = Number(event);
        this.fetchData.emit(this.pagingConfig);
    }
    onTableSizeChange(event: any): void {
        this.pagingConfig.itemsPerPage = Number(event.target.value);
        this.pagingConfig.currentPage = 1;
        this.fetchData.emit(this.pagingConfig);
    }

    onClick(row: any) {
        this.emitRowClick.emit(row);
    }

}