
<div class="cnt-box">
    <form [formGroup]="noteForm" (ngSubmit)="onSubmit()">
        <div class="d-flex flex-column">
            <div class="form-group">
                <div class="input-group"
                    [ngClass]="formUtils.hasValidationError(noteForm,'text') ? 'input-invalid' : 'input-white' ">
                    <!-- <div class="input-group-prepend pl-2">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref">Description</label>
                    </div> -->
                    <textarea class="form-control border-1" formControlName="text" rows="3" placeholder="Please enter your note here"></textarea>
                </div>
                <div *ngIf="formUtils.showValidationHint(noteForm,'text', 'required')"
                    class="input-hint">
                    {{ 'message.validation.required' | translate }}
                </div>
            </div>

            <!-- Action buttons-->
            <div class="d-flex justify-content-end">
        
                <!-- <div class="w-40">
                    <button type="button" (click)="onClickCancel()"
                        class="btn btn-block btn-outline-danger">Cancel
                    </button>
                </div> -->
                <div class="ml-3 w-20">
                    <button type="submit" [disabled]="isFormSubmit"
                        class="btn btn-block btn-danger ld-ext-right"
                        [ngClass]="{'running':isFormSubmit}">Add note
                        <div class="ld ld-ring ld-spin"></div>
                    </button>
                </div>

            </div>

        </div>
    </form>

    <!-- Notes view section -->
    <div class="mt-50" *ngIf="notes != null && notes.length > 0">
        
        <div *ngFor="let note of notes">
            <hr/>
            <div class="note-time">Note added: {{note.createdDatetime}}</div>
            <!-- <div>User Name</div> -->
            <div>{{note.text}}</div>
        </div>
    </div>

</div>
