import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { ApiError } from '../../model/api-error';
import { GuestService } from '../../service/guest.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { LoggerService } from '../../service/logger.service';

import { LayoutService } from '../../service/layout.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  apiError: ApiError;
  isFormSubmit = false;
  contactForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private guestService: GuestService,
    private logger: LoggerService,
    public formUtils: FormUtilsService,
    public layoutService: LayoutService) { }


  ngOnInit() {
    this.createForm();
  }

  createForm() {  
    this.contactForm = this.fb.group({

      name: [null, [Validators.required]],
      email: [null, [Validators.required]],      
      phoneNumber: [null],      
      service: [null, [Validators.required]],      
      message: [null, [Validators.required]]          
    });
  }

  onSubmit() {
    this.apiError = null;
    this.logger.debug('Submitted form value : ' + JSON.stringify(this.contactForm.value));
    
    if (this.contactForm.valid) {
      this.isFormSubmit = true;

      this.guestService.sendContactUsEmail(this.contactForm.value).subscribe(
        data => {
          // this.apiError = null;  
          this.apiError = new ApiError();
          this.apiError.message='Thank you for contacting us. We will get back to you soon.';  
          this.contactForm.reset();     
          this.isFormSubmit = false;   
        },
        error => {
          this.logger.error('Error : ' + JSON.stringify(error));
          // this.apiError = error;
          this.isFormSubmit = false;
        }      
      );
      
    } else {
      // show validation error
      //this.logger.debug('Submitted form value is not valid');
      this.formUtils.markFormGroupTouched(this.contactForm); 
    }
  }



  onClickClose() {
    this.apiError = null;
  }

}
