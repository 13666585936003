<div style="margin-top: 100px;">
    <h2 style="text-align: center;">OUR PARTNERS</h2>
            <!--partners slider-->
            <div id="app-partner" class="owl-carousel owl-theme">
                <div class="item" style="width:150px"><a href="#"><img src="assets/images/1.png" alt="" width="50" height=""></a></div>
                <div class="item"  style="width:150px"><a href="#"><img src="assets/images/2.png" alt="" width="50" height=""></a></div>
                <div class="item" style="width:150px"><a href="#"><img src="assets/images/3.png" alt="" width="50" height=""></a></div>
                <div class="item" style="width:150px"><a href="#"><img src="assets/images/4.png" alt="" width="50" height=""></a></div>
                <div class="item" style="width:150px"><a href="#"><img src="assets/images/5.png" alt="" width="50" height=""></a></div>
                <div class="item" style="width:150px"><a href="#"><img src="assets/images/6.png" alt="" width="50" height=""></a></div>
                <div class="item" style="width:150px"><a href="#"><img src="assets/images/7.png" alt="" width="50" height=""></a></div>
            </div>
</div>