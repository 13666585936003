    <div class="app-container">
        <section>
            <article>
                <h1>Who&nbsp;Are&nbsp;We?</h1>
                <p>College Admin streamlines the admissions process by joining hands with international students 
                    and scholastic colleges and universities across Canada. We are an esteemed part of Canada Visa Consultant. </p>
            </article>
            <article>
                <img src="assets/images/who-are-we.png" alt="who-are-we" width="600" height="">
            </article>
        </section>
    </div>
    <div class="app-container">
        <section id="rev-wrap">
            <article>
                <img src="assets/images/online-offer-letter-about.png" alt="about-us" width="600" height="">
            </article>
            <article>
                <h1>About&nbsp;US</h1>
                <p>We have been in the immigration and student recruitment industry for more than a decade now. Their presence in the Canadian Market has given 
                    us an upper hand in many ways. We are associated with more than 100 private and public institutes.
                    The impulse to create this online platform was to eliminate the cost incurred by the international student, 
                    during the admission process. Coming to Canada as a student or continuing education in Canada is a nerve-racking 
                    thought and charging a hefty amount from the international students to secure admission in a prestigious college or 
                    university in Canada, does no justice. The admission process is quite complex; thus, we aim to make it less cumbersome. 
                    The applications to secure admission at any institute can be done at your own disposal. </p>
            </article>
        </section>
    </div>
    <div class="app-container">
        <section>
            <article>
                <h2>College Admin Serves You With The Best Deals Available In The Market At No Cost At All.</h2>
                <p>Our genuine advice and cashback options can help you move forward with the surety and trust in minds.</p>
                <!-- <button class="learn-more">Apply Now </button> -->
                <a [routerLink]="['/courses/search']" class="btn-learn-more">Apply Now</a>
            </article>
            <article>
                <img src="./assets/images/online-offer-letter-best-deals.png" alt="online-offer-letter-best-deals" width="600" height="">
            </article>
        </section>
    </div>
   
    <!--Partners Carousal-->


    
