import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ApiError } from '../../model/api-error';
import { AppConstants } from '../../app.constants';
import { CourseApplicationSummary } from '../../model/course-application-summary.model';
import { StaticDataType } from '../../enums/static-data-type.enum';
import { StaticData } from '../../model/static-data.model';
import { IntakeDate } from '../../model/intake-date.model';
import { CourseApplicationStats } from '../../model/course.application.stats.model';
import { ConfirmationResponse } from '../../model/confirmation-response.model';
import { CourseApplicationFilter } from '../../model/course-application-filter.model';
import { CourseApplicationService } from '../../service/course-application.service';
import { StaticDataService } from '../../service/static-data.service';
import { LayoutService } from '../../service/layout.service';
import { AuthService } from '../../service/auth.service';
import { UtilService } from '../../service/util.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { LoggerService } from '../../service/logger.service';

@Component({
  selector: 'app-course-applications',
  templateUrl: './course-applications.component.html',
  styleUrls: ['./course-applications.component.scss']
})
export class CourseApplicationsComponent implements OnInit {

  courseApplications: CourseApplicationSummary[];
  apiError: ApiError;

  isFormSubmit = false;

  applicationFilterForm: UntypedFormGroup;
  intakeDates: IntakeDate[];
  courseApplicationStats: CourseApplicationStats;

  applicationStatuses: StaticData[];
  partnerId: number = null;

  // pagination variables
  pageRotate = true; // to center the page no - pagination component
  pageMaxSize = 5;  // no of visible pages - pagination component
  pageCount = 1; // assume that we have atleast 1 page available, actual count will be returned from backend
  totalCount = 0; // total no of pages, returned from backend
  pageSize = 0; // configured page size for the view, returned from nackend
  currentPage = 1; // by default, load the first page - keeps the track of the page

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private courseApplicationService: CourseApplicationService,    
    private staticDataService: StaticDataService,
    public authService: AuthService,
    private logger: LoggerService,
    public layoutService: LayoutService,
    public utilService: UtilService,
    public formUtils: FormUtilsService
  ) { }

  ngOnInit() {
    this.createForm();
    // user list component can pass query param as student email to check their applications
    let paramStudentEmail = this.route.snapshot.queryParamMap.get('paramStudentEmail') || null;
    if (paramStudentEmail != null) {
      this.applicationFilterForm.get('studentEmail').setValue(paramStudentEmail);
    }
    this.loadStaticDataList(StaticDataType.COURSE_APPL_STATUS);
    
    this.getCourseApplications(this.buildApplicationFilter(), this.currentPage);
    this.intakeDates = this.utilService.generateCourseIntakesDateOptions();
    
    if (this.authService.isUserPartner() || this.authService.isUserCertifiedPartner()) {
      this.partnerId = this.authService.getLoggedInUserId();
    }
    this.getCourseApplicationStats(null, this.partnerId);
  }

  createForm() {  
    this.applicationFilterForm = this.fb.group({
      studentName: [null],
      studentEmail: [null],
      instituteName: [null],
      courseStartDate: [null],
      applicationStatus: [null]    
    });
  }

  getCourseApplications(applicationFilter: CourseApplicationFilter, pageNumber: number) {
    this.logger.debug('Application filter : ' + JSON.stringify(applicationFilter));
    this.courseApplicationService.getAllStudentCourseApplications(applicationFilter, pageNumber)
      .subscribe(        
        resp => {  
          //this.isLoading = false;        
          this.pageCount = Number(resp.headers.get(AppConstants.http_header_page_count));
          this.totalCount = Number(resp.headers.get(AppConstants.http_header_total_count));
          this.pageSize = Number(resp.headers.get(AppConstants.http_header_page_size));
          this.courseApplications = resp.body;
          this.logger.debug('Got the student course applications : ' + JSON.stringify(this.courseApplications.length));          
          this.isFormSubmit = false;
        },
        error => {         
          this.logger.debug('Error while fetching course applications : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }
      );
  }

  getCourseApplicationStats(applicationStatusId: number, partnerId: number) {
    this.courseApplicationService.getStudentCourseApplicationsStats(applicationStatusId, partnerId)
      .subscribe(        
        data => {  
          //this.isLoading = false;        
          this.courseApplicationStats = data;
          this.logger.debug('Got the student course applications stats: ' + JSON.stringify(this.courseApplicationStats));
        },
        error => {         
          this.logger.debug('Error while fetching course applications stats : ' + JSON.stringify(error));
          this.apiError = error;          
        }
      );
  }

  loadStaticDataList(staticDataType: StaticDataType) {
    this.staticDataService.getStaticDataList(staticDataType).subscribe(
      data => {
        if (StaticDataType.COURSE_APPL_STATUS == staticDataType) {
          this.applicationStatuses = data;
        }
        this.logger.debug('Got the ' + staticDataType + ': ' + JSON.stringify(data));        
      },
      error => {
        this.logger.error('Loading of ' + staticDataType + ' failed : ' + JSON.stringify(error));
      }      
    );
  }

  onSubmit() {
    this.apiError = null;
    this.logger.debug('Submitted form value : ' + JSON.stringify(this.applicationFilterForm.value));
    
    if (this.applicationFilterForm.valid) {
      this.isFormSubmit = true;     
      this.getCourseApplications(this.buildApplicationFilter(), this.currentPage); 
      // while filetering, refresh the stats
      if (this.applicationFilterForm.value.applicationStatus != null) {
        this.getCourseApplicationStats(this.applicationFilterForm.value.applicationStatus.id, this.partnerId);
      } else {
        this.getCourseApplicationStats(null, this.partnerId);
      }   
      
    } else {
      // show validation error
      this.logger.debug('Submitted form value is not valid');
      this.formUtils.markFormGroupTouched(this.applicationFilterForm); 
    }
  }

  buildApplicationFilter(): CourseApplicationFilter {
    let applicationFilter: CourseApplicationFilter = new CourseApplicationFilter();
      // for a student view, show only that student applications
      if (this.authService.isUserStudent()) {
        applicationFilter.userId = this.authService.getLoggedInUserId();
        // For a partner / certified partner, he is only allowed to see the details of student created by him
      } else if (this.authService.isUserPartner() || this.authService.isUserCertifiedPartner()) {
        applicationFilter.partnerId = this.authService.getLoggedInUserId();
      }   

      if (this.applicationFilterForm.value.applicationStatus != null) {
        applicationFilter.applicationStatusId = this.applicationFilterForm.value.applicationStatus.id;
      }

      applicationFilter.studentName = this.applicationFilterForm.value.studentName;
      applicationFilter.studentEmail = this.applicationFilterForm.value.studentEmail;
      applicationFilter.instituteName = this.applicationFilterForm.value.instituteName;
      applicationFilter.courseStartDate = this.applicationFilterForm.value.courseStartDate;

      return applicationFilter;
  }

  onConfirmationResponse(response: ConfirmationResponse) {
    //console.log('User confirmation response : ' + JSON.stringify(response));
    if (response.isConfirmed) {
      this.logger.debug('User confirmed - deleting the course application');
      this.deleteCourseApplication(response.entityId);
    } else {
      this.logger.debug('User declined - not deleting the course application');
    }
  }

  deleteCourseApplication(courseApplicationId: number) {
    this.apiError = null;
    this.courseApplicationService.deleteStudentCourseApplication(courseApplicationId)
      .subscribe(
        data => {
          for (let index in this.courseApplications) {
            if (this.courseApplications[index].id == courseApplicationId) {
              this.courseApplications.splice(Number(index), 1);
            }
          }          
          this.logger.debug('Deleted course application successfully : ' + JSON.stringify(data));         
        },
        error => {
          this.logger.debug('Error while deleting course application : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }
      );
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getCourseApplications(this.buildApplicationFilter(), this.currentPage);    
  }



}
