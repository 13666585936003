import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// ngx-bootstrap modules
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';



import { ApiErrorComponent } from './api-error/api-error.component';
import { ImageComponent } from './image/image.component';
import { BusyLoadingComponent } from './busy-loading/busy-loading.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { CourseApplicationsComponent } from './course-applications/course-applications.component';
import { ReferralListComponent } from './referral-list/referral-list.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { NoteComponent } from './note/note.component';
import { AttachmentComponent } from './attachment/attachment.component';
// google reCaptcha
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MatStepperModule } from '@angular/material/stepper';
import { TableWrapperComponent } from './table-wrapper/table-wrapper.component';
import { NgxPaginationModule } from 'ngx-pagination';

// google maps

// TO DO: Need To enable
//import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [
    ApiErrorComponent,
    ImageComponent,
    BusyLoadingComponent,
    WorkInProgressComponent,
    CourseApplicationsComponent,
    ReferralListComponent,
    ConfirmationModalComponent,
    NoteComponent,
    AttachmentComponent,
    TableWrapperComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BsDropdownModule,
    BsDatepickerModule,
    TooltipModule,
    ModalModule,
    NgxPaginationModule,
    PaginationModule,
    TypeaheadModule,
    TabsModule,
    //AgmCoreModule, // TO DO: Need To enable
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    TranslateModule.forChild(),
    MatStepperModule
  ],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    BsDatepickerModule,
    TooltipModule,
    ModalModule,
    NgxPaginationModule,
    PaginationModule,
    TypeaheadModule,
    TabsModule,
    //AgmCoreModule, // TO DO: Need To enable
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    TranslateModule,
    ApiErrorComponent,
    ImageComponent,
    BusyLoadingComponent,
    CourseApplicationsComponent,
    ReferralListComponent,
    ConfirmationModalComponent,
    NoteComponent,
    AttachmentComponent,
    TableWrapperComponent,
    MatStepperModule
  ]
})
export class SharedModule { }
