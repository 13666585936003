import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { CourseApplicationDocument } from '../model/course-application-document.model';
import { StudentCourseApplication } from '../model/student-course-application.model';
import { CourseApplicationSummary } from '../model/course-application-summary.model';
import { CourseApplication } from '../model/course-application.model';
import { CourseApplicationStats } from '../model/course.application.stats.model';
import { CourseApplicationFilter } from '../model/course-application-filter.model';
import { UtilService } from './util.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class CourseApplicationService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  public createStudentCourseApplication(courseApplication: CourseApplication): Observable<StudentCourseApplication> {   
    return this.http.post<StudentCourseApplication>(AppConstants.endpoint.course_application.base,
      courseApplication, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getAllStudentCourseApplications(applicationFilter: CourseApplicationFilter, pageNumber: number): Observable<HttpResponse<CourseApplicationSummary[]>> {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber.toString());
    if (applicationFilter != null) {
      if (applicationFilter.userId != null) {
        params = params.append('userId', applicationFilter.userId.toString());
      }
      if (applicationFilter.partnerId != null) {
        params = params.append('partnerId', applicationFilter.partnerId.toString());
      }
      if (applicationFilter.studentName != null) {
        params = params.append('studentName', applicationFilter.studentName);
      }  
      if (applicationFilter.studentEmail != null) {
        params = params.append('studentEmail', applicationFilter.studentEmail);
      }  
      if (applicationFilter.instituteName != null) {
        params = params.append('instituteName', applicationFilter.instituteName);
      }
      if (applicationFilter.courseStartDate != null) {
        params = params.append('courseStartDate', applicationFilter.courseStartDate);
      }  
      if (applicationFilter.applicationStatusId != null) {
        params = params.append('applicationStatusId', applicationFilter.applicationStatusId.toString());
      }     
    }
     
    return this.http.get<CourseApplicationSummary[]>(AppConstants.endpoint.course_application.base, {params: params, observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getStudentCourseApplication(studentCourseApplicationId: number): Observable<StudentCourseApplication> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course_application.get, studentCourseApplicationId);
    return this.http.get<StudentCourseApplication>(url)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public updateStudentCourseApplication(studentCourseApplicationId: number, value: any): Observable<StudentCourseApplication> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course_application.get, studentCourseApplicationId);
    return this.http.put<StudentCourseApplication>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteStudentCourseApplication(studentCourseApplicationId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course_application.get, studentCourseApplicationId);
    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public addStudentCourseApplicationDocument(studentCourseApplicationId: number, value: CourseApplicationDocument): Observable<CourseApplicationDocument> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course_application.documents, studentCourseApplicationId);
    return this.http.post<CourseApplicationDocument>(url, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public deleteStudentCourseApplicationDocument(studentCourseApplicationId: number, courseApplicationDocumentId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.course_application.documents, studentCourseApplicationId);
    url = url + '/' + courseApplicationDocumentId;
    return this.http.delete<any>(url, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public getStudentCourseApplicationsStats(applicationStatusId: number, partnerId: number): Observable<CourseApplicationStats> {
    let params = new HttpParams();
    if (applicationStatusId != null) {
      params = params.append('applicationStatusId', applicationStatusId.toString());
    }
    if (partnerId != null) {
      params = params.append('partnerId', partnerId.toString());
    }
    return this.http.get<CourseApplicationStats>(AppConstants.endpoint.course_application.stats, {params : params})
      .pipe(catchError(this.exceptionService.handleError));
  }


}
