import { Component, OnInit, HostListener, AfterViewInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../service/auth.service';
import { LayoutService } from '../../service/layout.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('navMenuToggleInput') navMenuToggleInput: ElementRef;

  isCollapsed = true;
  isStickyMenuVisible = false;
  rsMenuClass: string;
  showSignOut = false;
  @Input() isSignInPage: boolean;

  navItems = [
    //{ header: 'HOME', icon: '', route: '/home', role: 'GUEST' },
    { header: 'About us', icon: '', route: '/about', role: 'GUEST' },
    { header: 'Benefits', icon: '', route: '/benefits', role: 'GUEST' },
    { header: 'Our Services', icon: '', route: '/services', role: 'GUEST' },
    { header: 'Referrals', icon: '', route: '/referrals', role: 'GUEST' },
    { header: 'Blog', icon: '', route: '/blog', role: 'GUEST' },
    //{ header: 'contact us', icon: '', route: '/contact', role: 'GUEST' },

    { header: 'Dashboard', icon: '', route: '/student/dashboard', role: 'STUDENT' },
    // { header: 'Profile', icon: 'business_center', route: '/student-profile', role: 'STUDENT' },
    { header: 'Institutes', icon: '', route: '/institutes/search', role: 'STUDENT' },
    { header: 'Programs', icon: '', route: '/courses/search', role: 'STUDENT' },
    { header: 'Referrals', icon: '', route: '/referrals', role: 'STUDENT' },
    { header: 'Blogs', icon: '', route: '/blog', role: 'STUDENT' },


    { header: 'Dashboard', icon: '', route: '/admin/dashboard', role: 'ADMIN' },
    { header: 'Institutes', icon: '', route: '/institutes/search', role: 'ADMIN' },
    { header: 'Programs', icon: '', route: '/courses/search', role: 'ADMIN' },
    { header: 'Blogs', icon: '', route: '/blog', role: 'ADMIN' },

    { header: 'Dashboard', icon: '', route: '/admin/dashboard', role: 'PARTNER' },
    { header: 'Institutes', icon: '', route: '/institutes/search', role: 'PARTNER' },
    { header: 'Programs', icon: '', route: '/courses/search', role: 'PARTNER' },
    { header: 'Blogs', icon: '', route: '/blog', role: 'PARTNER' },
    // { header: 'Referrals', icon: '', route: '/referrals', role: 'PARTNER' },

    { header: 'Dashboard', icon: '', route: '/admin/dashboard', role: 'PARTNER_CERT' },
    { header: 'Institutes', icon: '', route: '/institutes/search', role: 'PARTNER_CERT' },
    { header: 'Programs', icon: '', route: '/courses/search', role: 'PARTNER_CERT' },
    { header: 'Blogs', icon: '', route: '/blog', role: 'PARTNER_CERT' }
    // { header: 'Referrals', icon: '', route: '/referrals', role: 'PARTNER_CERT' },

  ];

  navDropdownItems = [
    { header: 'Profile', icon: 'business_center', route: '/student-profile', role: 'STUDENT' },
    { header: 'Profile', icon: 'business_center', route: '/student-profile', role: 'PARTNER' },
    { header: 'Profile', icon: 'business_center', route: '/student-profile', role: 'PARTNER_CERT' }
  ];

  destroyed$ = new Subject();
  userProfileDetails: any;

  constructor(
    private router: Router,
    public layoutService: LayoutService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    //this.rsMenuClass = 'rs-menu-hide';
    this.getUserProfileDetails();
  }

  getUserProfileDetails(): void {
    this.authService.getUserProfile()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        res => {
          this.userProfileDetails = res;

        },
        err => console.log(err)
      )
  }

  ngAfterViewInit() {
    //this.rsMenuClass = 'rs-menu-hide';
    (function ($) {
      /* if($(window).width() < 992) {
        $('.rs-menu').css('height', '0');
        $('.rs-menu').css('opacity', '0');
        $('.rs-menu-toggle').on( 'click', function(){
         $('.rs-menu').css('opacity', '1');
       });
      } */

      /* $(document).ready(function () {
      	
        $(window).on( 'load', function() {
          //rs menu
          
        })

      }); */
    })(jQuery);
  }

  onClickToggleMenu() {
    this.rsMenuClass = 'rs-menu-hide';
  }

  @HostListener('window:scroll', ['$event'])
  showStickyMenu(event) {
    // console.debug("Scroll Event", document.body.scrollTop);      
    //console.log("Scroll Event", window.pageYOffset );      
    if (window.pageYOffset > 300) {
      //console.log('Adding sticky class');
      this.isStickyMenuVisible = true;
    } else {
      this.isStickyMenuVisible = false;
    }
  }

  getFilteredNavItemsByRole() {
    /* let role = 'GUEST';
    if (this.authService.isUserLoggedIn()) {
      role = this.authService.getLoggedInUserRole();
    } */

    if (this.isUserLoggedIn() && !this.authService.isLoggedInUserEnabled()) {
      return null;
    }

    let role = this.authService.getLoggedInUserRole();
    return this.navItems.filter(
      navItem => navItem.role == role
    );

  }

  getFilteredNavDropdownItemsByRole() {
    if (this.isUserLoggedIn() && !this.authService.isLoggedInUserEnabled()) {
      return null;
    }

    let role = this.authService.getLoggedInUserRole();
    return this.navDropdownItems.filter(
      navItem => navItem.role == role
    );
  }

  getFilteredNavDropdownItemsByRoleForMobile() {
    if (this.layoutService.isMobile()) {
      return this.getFilteredNavDropdownItemsByRole();
    } else {
      return null;
    }
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn();
  }

  getLoggedInUserName() {
    let name = 'User';
    if (this.authService.getLoggedInUserFullName() != null) {
      name = this.authService.getLoggedInUserFullName();
    }

    return name;
  }

  onLogout() {
    this.closeNav();
    this.authService.logout();
  }

  onDropdownLinkClick(url: string) {
    console.log('Url clicked : ' + url)
    this.router.navigateByUrl(url);
  }

  closeSideNavAndNavigateToURL(url: string) {
    this.closeNav();
    this.router.navigateByUrl(url);
  }

  // openNav() {
  //   document.getElementById("mySidenav").style.width = "200px";
  // }

  closeNav() {
    if (this.layoutService.isMobile()) {
      this.navMenuToggleInput.nativeElement.checked = false;
    }
  }

  showSignOutMenu(): void {
    this.showSignOut = !this.showSignOut;
  }

  onSignOut(): void {
    this.showSignOutMenu();
    this.onLogout();
    //this.router.navigate(["auth", "signin"]);
  }

  onClick(): void {
    if (this.isUserLoggedIn()) {
      this.router.navigate(["admin", "dashboard"]);
    }
  }

  onAdminProfile(): void {
    this.router.navigate(["admin", "profile"]);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
