import { Component, TemplateRef, OnInit} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LayoutService } from '../../service/layout.service';
import { Router } from '@angular/router';

declare var jQuery: any;
//declare var $: any;
//import 'magnific-popup';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	modalRef: BsModalRef;

	constructor(private modalService: BsModalService,
		private router: Router,
		public layoutService: LayoutService) { }

	ngOnInit() {
		(function ($) {
			$(document).ready(function () {
				/* $(window).on('load', function () {
					setTimeout(function () {
						$('#subscribeModal').modal('show');
					}, 30000);
				}); */

				$("#exampleModalCenterVid").on('hidden.bs.modal', function (e) {
					$("#exampleModalCenterVid iframe").attr("src", $("#exampleModalCenterVid iframe").attr("src"));
				});

				$('.owl-carousel').owlCarousel({
					loop:true,
					margin:10,
					nav:false,
					autoplay:true,
					autoplayTimeout:5000,
					stagePadding:50,
					dots:false,
					responsive:{
						0:{
							items:1
						},
						600:{
							items:1
						},
						1000:{
							items:3
						}
					}
				});


				//Slider js 
				/*-------------------------------------
					   Home page Slider
					   -------------------------------------*/
				// Declare Carousel jquery object
				/* var owl = $('#home-slider');

				// Carousel initialization
				owl.owlCarousel({
					loop: true,
					margin: 0,
					navSpeed: 800,
					nav: true,
					navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
					items: 1,
					autoplay: true,
					transitionStyle: "fade",
				});

				// add animate.css class(es) to the elements to be animated
				function setAnimation(_elem, _InOut) {
					// Store all animationend event name in a string.
					// cf animate.css documentation
					var animationEndEvent = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

					_elem.each(function () {
						var $elem = $(this);
						var $animationType = 'animated ' + $elem.data('animation-' + _InOut);

						$elem.addClass($animationType).one(animationEndEvent, function () {
							$elem.removeClass($animationType); // remove animate.css Class at the end of the animations
						});
					});
				}

				// Fired before current slide change
				owl.on('change.owl.carousel', function (event) {
					var $currentItem = $('.owl-item', owl).eq(event.item.index);
					var $elemsToanim = $currentItem.find("[data-animation-out]");
					setAnimation($elemsToanim, 'out');
				});

				// Fired after current slide has been changed
				owl.on('changed.owl.carousel', function (event) {

					var $currentItem = $('.owl-item', owl).eq(event.item.index);
					var $elemsToanim = $currentItem.find("[data-animation-in]");
					setAnimation($elemsToanim, 'in');
				}); */

				/*-------------------------------------
				OwlCarousel
				-------------------------------------*/
				/*$('.rs-carousel').each(function () {
					var owlCarousel = $(this),
						loop = owlCarousel.data('loop'),
						items = owlCarousel.data('items'),
						margin = owlCarousel.data('margin'),
						stagePadding = owlCarousel.data('stage-padding'),
						autoplay = owlCarousel.data('autoplay'),
						autoplayTimeout = owlCarousel.data('autoplay-timeout'),
						smartSpeed = owlCarousel.data('smart-speed'),
						dots = owlCarousel.data('dots'),
						nav = owlCarousel.data('nav'),
						navSpeed = owlCarousel.data('nav-speed'),
						xsDevice = owlCarousel.data('mobile-device'),
						xsDeviceNav = owlCarousel.data('mobile-device-nav'),
						xsDeviceDots = owlCarousel.data('mobile-device-dots'),
						smDevice = owlCarousel.data('ipad-device'),
						smDeviceNav = owlCarousel.data('ipad-device-nav'),
						smDeviceDots = owlCarousel.data('ipad-device-dots'),
						mdDevice = owlCarousel.data('md-device'),
						mdDeviceNav = owlCarousel.data('md-device-nav'),
						mdDeviceDots = owlCarousel.data('md-device-dots');

					 owlCarousel.owlCarousel({
						loop: (loop ? true : false),
						items: (items ? items : 4),
						lazyLoad: true,
						margin: (margin ? margin : 0),
						autoplay: (autoplay ? true : false),
						autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
						smartSpeed: (smartSpeed ? smartSpeed : 250),
						dots: (dots ? true : false),
						nav: (nav ? true : false),
						navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
						navSpeed: (navSpeed ? true : false),
						responsiveClass: true,
						responsive: {
							0: {
								items: (xsDevice ? xsDevice : 1),
								nav: (xsDeviceNav ? true : false),
								dots: (xsDeviceDots ? true : false)
							},
							768: {
								items: (smDevice ? smDevice : 3),
								nav: (smDeviceNav ? true : false),
								dots: (smDeviceDots ? true : false)
							},
							992: {
								items: (mdDevice ? mdDevice : 4),
								nav: (mdDeviceNav ? true : false),
								dots: (mdDeviceDots ? true : false)
							}
						}
					}); 

				});*/


			});
		})(jQuery);

	}



		


	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, {class: 'modal-dialog-centered'});
	  }

	  OnClickGetStarted() {
		this.router.navigateByUrl('/courses/search');
	  }

}
