import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { SeoService } from './service/seo.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'study-offer-ui';
  isHomePage = false;
  isSignInPage = false;
  isAddPages = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private translate: TranslateService) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
 
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute);

        // TO DO: This is just to hide the side bar for Home page.
       this.isHomePage = rt.snapshot.routeConfig.path === "home";
       this.isSignInPage = rt.snapshot.routeConfig.path === "signin" || rt.snapshot.routeConfig.path.includes("password/");

        this.isAddPages = rt.snapshot.routeConfig.path === "add-institute" || rt.snapshot.routeConfig.path === "add-course";
        rt.data.subscribe(data => {
          //console.log(data);
          if (data.title) {
            this.seoService.setTitle(data.title);
          }
          if (data.description) {
            this.seoService.addDescriptionMetaTag(data.description);
          }
          
        })
      });

      
 
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    } 
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  
}
