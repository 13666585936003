import { Document } from './document.model';

export class Attachment {
    id: number;
	entityType: string;
	entityId: number;
	documentType: string;
	document: Document;
	description: string;
	createdDatetime: string;
}