<app-api-error [apiError]="apiError"></app-api-error>
<div>
    <form [formGroup]="attachmentForm" (ngSubmit)="onSubmit()">

        <div class="cnt-box mb-3">
            <div class="form-group">
                <div class="input-group"
                    [ngClass]="formUtils.hasValidationError(attachmentForm,'documentType') ? 'input-invalid' : 'input-white' ">
                    <div class="input-group-prepend pl-2">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref">Document Type</label>
                    </div>
                    <input type="text" class="form-control border-1 input-white-text" formControlName="documentType" />
                </div>
                <div *ngIf="formUtils.showValidationHint(attachmentForm,'documentType', 'required')"
                    class="input-hint">
                    {{ 'message.validation.required' | translate }}
                </div>
            </div>

            <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>

            <div class="form-group">
                <div class="input-group"
                    [ngClass]="formUtils.hasValidationError(attachmentForm,'documentName') ? 'input-invalid' : 'input-white' ">
                    <div class="input-group-prepend pl-2">
                        <!-- <span class="valign-center"><i class="material-icons md-dark">topic</i>
                            <span class="valign-center input-white-label">Document*</span></span> -->
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref">Document<span class="input-required">*</span></label>
                    </div>
                    
                    
                    <input type="text" class="form-control border-1 input-white-text"
                        formControlName="documentName" />
                    <div class="input-group-append cursor-hand ml-2" (click)="fileInput.click()">
                        <span class="valign-center"><i class="material-icons md-dark">publish</i></span>
                    </div>
                   
                
                </div>
                <div *ngIf="formUtils.showValidationHint(attachmentForm,'documentName', 'required')"
                    class="input-hint">
                    {{ 'message.validation.required' | translate }}
                </div>
            </div>

            <!-- Need to check if we need to add a description while uploading a document-->
            <!-- <div class="form-group">
                <div class="input-group"
                    [ngClass]="formUtils.hasValidationError(attachmentForm,'description') ? 'input-invalid' : 'input-white' ">
                    <div class="input-group-prepend pl-2">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref">Description</label>
                    </div>
                    <textarea class="form-control border-1" formControlName="description" rows="3"></textarea>
                </div>
                <div *ngIf="formUtils.showValidationHint(attachmentForm,'description', 'required')"
                    class="input-hint">
                    {{ 'message.validation.required' | translate }}
                </div>
            </div> -->

            <div class="text-right">
                <button type="submit" [disabled]="isFormSubmit"
                    class="w-25 btn btn-danger ld-ext-right"
                    [ngClass]="{'running':isFormSubmit}">Upload
                    <div class="ld ld-ring ld-spin"></div>
                </button>
            </div>

        </div>
    </form>
</div>

<div *ngIf="attachments != null && attachments.length > 0" class="cnt-box mt-3">
    <div class="cnt-header-txt cnt-txt-xl txt-bold txt-opac mb-3">Uploaded documents</div>
    <div class="d-flex flex-row" *ngFor="let attachment of attachments">
        <div class="w-25 cnt-header-txt txt-bold txt-opac">{{attachment.documentType}}</div>
        <div class="ml-2 flex-grow-1">{{attachment.document.name}}</div>
        <div class="cursor-hand" tooltip="download" (click)="onDownloadDocument(attachment.document.id)"><span class="valign-center"><i
                    class="material-icons md-dark">save_alt</i></span></div>
        <div class="cursor-hand ml-2" tooltip="delete" (click)="onDeleteDocument(attachment.id)"><span class="valign-center"><i
                    class="material-icons md-primary">clear</i></span></div>
    </div>
</div>
