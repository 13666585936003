<!-- <div class="text-right">
    <button class="btn btn-lg btn-danger" type="button" [routerLink]="['/referrals']">Refer now</button>
</div> -->
<div class="d-flex flex-row">
    <div class="mt-5 w-100 cnt-box" style="overflow-x:auto;">
        <div class="d-flex flex-row">
            <div *ngIf="authService.isUserStudent()" class="cnt-header-txt cnt-txt-lg txt-bold mb-3">My referrals
            </div>
            <div *ngIf="authService.isUserAdmin() || authService.isUserPartner() || authService.isUserCertifiedPartner()"
                class="cnt-header-txt cnt-txt-lg txt-bold mb-3">Referrals
            </div>
            <div class="ml-auto">
                <!-- <div> -->
                <form [formGroup]="referralFilterForm" (ngSubmit)="onSubmit()">
                    <div class="d-flex flex-row">

                        <div class="form-group mr-2">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(referralFilterForm,'referralDate') ? 'input-invalid' : 'input-white' ">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">event</i>
                                    </span>
                                </div> -->
                                <input type="text" class="form-control border-0 input-white-text"
                                    placeholder="Refer date" #dp1="bsDatepicker" bsDatepicker
                                    (bsValueChange)="onReferralDateValueChange($event)"
                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }" />
                                <!-- <div class="input-group-append cursor-hand" (click)="dp1.toggle()">
                                    <span class="valign-center"><i
                                            class="material-icons md-dark">calendar_today</i></span>
                                </div> -->
                            </div>
                        </div>

                        <div class="form-group mr-2">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(referralFilterForm,'referToEmail') ? 'input-invalid' : 'input-white' ">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">email</i>
                                    </span>
                                </div> -->
                                <input class="form-control border-0 input-white-text" formControlName="referToEmail"
                                    placeholder="Refer to email">
                            </div>
                            <div *ngIf="formUtils.showValidationHint(referralFilterForm,'referToEmail', 'required')"
                                class="input-hint">
                                {{ 'message.validation.required' | translate }}
                            </div>
                        </div>

                        <!-- <div *ngIf="authService.isUserAdmin()" class="form-group mr-2">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(referralFilterForm,'referByEmail') ? 'input-invalid' : 'input-white' ">                                
                                <input class="form-control border-0 input-white-text" formControlName="referByEmail"
                                    placeholder="Refer by email">
                            </div>
                            <div *ngIf="formUtils.showValidationHint(referralFilterForm,'referByEmail', 'required')"
                                class="input-hint">
                                {{ 'message.validation.required' | translate }}
                            </div>
                        </div> -->


                        <div class="form-group">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(referralFilterForm,'paymentStatus') ? 'input-invalid' : 'input-white' ">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">list_alt</i>
                                    </span>
                                </div> -->
                                <select [compareWith]="formUtils.compareStaticData"
                                    class="form-control border-0 select-white-text" formControlName="paymentStatus">
                                    <option [ngValue]="null">Select status</option>
                                    <option *ngFor="let paymentStatus of paymentStatuses" [ngValue]="paymentStatus">
                                        {{paymentStatus.value}}</option>
                                </select>
                            </div>
                            <div *ngIf="formUtils.showValidationHint(referralFilterForm,'paymentStatus', 'required')"
                                class="input-hint">
                                {{ 'message.validation.required' | translate }}
                            </div>
                        </div>

                        <div class="ml-2">
                            <button type="submit" [disabled]="isFormSubmit" class="btn btn-danger ld-ext-right"
                                [ngClass]="{'running':isFormSubmit}">Filter
                                <div class="ld ld-ring ld-spin"></div>
                            </button>
                        </div>
                    </div>

                </form>
                <!-- </div> -->

            </div>
        </div>


        <div [ngClass]="authService.isUserAdmin() ? 'referral-container-admin' : 'referral-container' ">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Refer to name</th>
                        <th scope="col">Refer to email</th>
                        <th scope="col">Refer to contact</th>
                        <th scope="col">Refer date</th>
                        <th *ngIf="authService.isUserAdmin()" scope="col">Refer by name</th>
                        <th *ngIf="authService.isUserAdmin()" scope="col">Refer by email</th>
                        <th scope="col">Status</th>
                        <th scope="col"><i class="material-icons md-dark">menu</i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let referral of referrals">
                        <th scope="row">{{referral.referToName}}</th>
                        <td>{{referral.referToEmail}}</td>
                        <td>{{referral.referToContactCountyCode.countryCode}} {{referral.referToContactNumber}}</td>
                        <td>{{referral.referralDate}}</td>
                        <td *ngIf="authService.isUserAdmin()">
                            <span *ngIf="referral.referByStudentName"><a class="cnt-link cnt-link-hover"
                                    [routerLink]="['/student-profile',referral.referByStudentUserId,'details']">{{referral.referByStudentName}}</a></span>
                            <span *ngIf="referral.referByName">{{referral.referByName}}</span>
                        </td>
                        <td *ngIf="authService.isUserAdmin()">{{referral.referByEmail}}</td>
                        <td><span *ngIf="referral.paymentStatus">{{referral.paymentStatus.value}}</span></td>
                        <td>
                            <a class="nav-item nav-link navbar-text valign-center"
                                [routerLink]="['/referrals', referral.id, 'details']" tooltip="View referral">
                                <i class="material-icons md-dark md-18">visibility</i><span></span></a>
                            <span *ngIf="authService.isUserStudent()">
                                <app-confirmation-modal [entityId]="referral.id"
                                    (confirmationResponse)="onConfirmationResponse($event)"></app-confirmation-modal>
                            </span>

                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="text-center" *ngIf="referrals == null || referrals.length == 0">
                <div class="cnt-header-txt cnt-txt-xl txt-bold txt-opac">No referrals found</div>
            </div>
        </div>

        <div class="pagination justify-content-center" *ngIf="totalCount > pageSize">
            <pagination [totalItems]="totalCount" [itemsPerPage]="pageSize" [(ngModel)]="currentPage"
                [rotate]="pageRotate" [maxSize]="pageMaxSize" previousText="&lsaquo;" nextText="&rsaquo;"
                (pageChanged)="pageChanged($event)">
            </pagination>
        </div>
    </div>
</div>