<div class="d-flex flex-column flex-shrink-0 p-3 bg-light h-100" style="width: 280px;">
    <!--<a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
      <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg>
      <span class="fs-4">Sidebar</span>
    </a>
    <hr>-->
    <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['/admin/dashboard']" routerLinkActive="active sidebar-active">
                <i class="bi bi-house"></i>
                Dashboard
            </a>
        </li>
        <li>
            <a class="nav-link link-dark" [routerLink]="['/access-management']" routerLinkActive="active sidebar-active">
                <i class="bi bi-gear"></i>
                Access Management
            </a>
        </li>
        <li>
            <a class="nav-link link-dark" [routerLink]="['/staff']" routerLinkActive="active sidebar-active">
                <i class="bi bi-people-fill"></i>
                Staff
            </a>
        </li>
        <li>
            <a class="nav-link link-dark" [routerLink]="['/institutes/dashboard']"  routerLinkActive="active sidebar-active">
                <i class="bi bi-receipt-cutoff"></i>
                Institutions
            </a>
        </li>
        <li>
            <a class="nav-link link-dark" [routerLink]="['/reports']"  routerLinkActive="active sidebar-active">
                <i class="bi bi-file-text"></i>
                Reports
            </a>
        </li>
    </ul>

</div>