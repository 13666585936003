import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { Note } from '../model/note.model';
import { NoteFilter } from '../model/note-filter.model';
import { UtilService } from './util.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  public getNotes(filter: NoteFilter, pageNumber: number): Observable<HttpResponse<Note[]>> {
    let params = new HttpParams();
    if (filter != null) {
      if (filter.entityType != null) {
        params = params.append('entityType', filter.entityType);
      }
      if (filter.entityId != null) {
        params = params.append('entityId', filter.entityId.toString());
      }
     
    }
    params = params.append('pageNumber', pageNumber.toString());

    return this.http.get<Note[]>(AppConstants.endpoint.note.base, {params: params, observe: 'response'})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public createNote(value: any): Observable<Note> {
    return this.http.post<Note>(AppConstants.endpoint.note.base, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

}
