import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';
  import { Observable } from 'rxjs';

  import { AppConstants } from '../app.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        // Get the user auth token
        const idToken = localStorage.getItem(AppConstants.authTokenKey);
        //console.log('In auth interceptor : token :: ' + idToken);

        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.append("Authorization",
                    "Bearer " + idToken)
            });

            //console.log('In auth interceptor : modified request :: ' + JSON.stringify(cloned));

            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}