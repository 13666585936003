<!-- course application stats -->
<div *ngIf="(authService.isUserAdmin() || authService.isUserPartner() || authService.isUserCertifiedPartner()) && courseApplicationStats" class="d-flex flex-row justify-content-center mb-3">
    <div class="cnt-box stats-cnt">
        <div class="text-center cnt-header-txt cnt-txt-xl mb-2">Daily</div>
        <div class="text-center cnt-txt-xl txt-bold">{{courseApplicationStats.dailyCount}}</div>        
    </div>
    <div class="ml-3 cnt-box stats-cnt">
        <div class="text-center cnt-header-txt cnt-txt-lg mb-2">Monthly</div>
        <div class="text-center cnt-txt-xl txt-bold">{{courseApplicationStats.monthlyCount}}</div>        
    </div>
    <div class="ml-3 cnt-box stats-cnt">
        <div class="text-center cnt-header-txt cnt-txt-xl mb-2">Yearly</div>
        <div class="text-center cnt-txt-xl txt-bold">{{courseApplicationStats.yearlyCount}}</div>        
    </div>
</div>
<div class="d-flex flex-row">
    <div class="mt-5 w-100 cnt-box" style="overflow-x:auto;">
        <app-api-error [apiError]="apiError"></app-api-error>
        <div class="d-flex flex-row">
            <div *ngIf="authService.isUserStudent()" class="cnt-header-txt cnt-txt-lg txt-bold mb-3">My Applicatons
            </div>
            <div *ngIf="authService.isUserAdmin()" class="cnt-header-txt cnt-txt-lg txt-bold mb-3">Program Applicatons
            </div>
            <div class="ml-auto">
                <!-- <div> -->
                <form [formGroup]="applicationFilterForm" (ngSubmit)="onSubmit()">
                    <div class="d-flex flex-row">

                        <div *ngIf="authService.isUserAdmin()" class="form-group">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(applicationFilterForm,'studentName') ? 'input-invalid' : 'input-white' ">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">face</i>
                                    </span>
                                </div> -->
                                <input class="form-control border-1 input-white-text" formControlName="studentName"
                                    placeholder="Student name">
                            </div>
                        </div>

                        <div *ngIf="authService.isUserAdmin()" class="form-group">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(applicationFilterForm,'studentEmail') ? 'input-invalid' : 'input-white' ">
                                <input class="form-control border-1 input-white-text" formControlName="studentEmail"
                                    placeholder="Student email">
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(applicationFilterForm,'instituteName') ? 'input-invalid' : 'input-white' ">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">title</i>
                                    </span>
                                </div> -->
                                <input class="form-control border-1 input-white-text" formControlName="instituteName"
                                    placeholder="Institute name">
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group input-white">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">calendar_today</i>                                        
                                    </span>
                                </div> -->
                                <select class="form-control border-1 select-white-text"
                                    formControlName="courseStartDate">
                                    <option [ngValue]="null">Select intake</option>
                                    <option *ngFor="let intakeDate of intakeDates" [ngValue]="intakeDate.startDate">
                                        {{intakeDate.formattedStartDate}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group"
                                [ngClass]="formUtils.hasValidationError(applicationFilterForm,'applicationStatus') ? 'input-invalid' : 'input-white' ">
                                <!-- <div class="input-group-prepend pl-2">
                                    <span class="valign-center"><i class="material-icons md-dark">list_alt</i>
                                    </span>
                                </div> -->
                                <select [compareWith]="formUtils.compareStaticData"
                                    class="form-control border-1 select-white-text" formControlName="applicationStatus">
                                    <option [ngValue]="null">Select status</option>
                                    <option *ngFor="let applicationStatus of applicationStatuses"
                                        [ngValue]="applicationStatus">{{applicationStatus.value}}</option>
                                </select>
                            </div>
                            <div *ngIf="formUtils.showValidationHint(applicationFilterForm,'applicationStatus', 'required')"
                                class="input-hint">
                                {{ 'message.validation.required' | translate }}
                            </div>
                        </div>

                        <div class="ml-2">
                            <button type="submit" [disabled]="isFormSubmit" class="btn btn-danger ld-ext-right"
                                [ngClass]="{'running':isFormSubmit}">Filter
                                <div class="ld ld-ring ld-spin"></div>
                            </button>
                        </div>
                    </div>

                </form>
                <!-- </div> -->

            </div>
        </div>


        <div [ngClass]="authService.isUserAdmin() ? 'application-container-admin' : 'application-container' ">
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">App #</th>
                        <th *ngIf="authService.isUserAdmin() || authService.isUserPartner() || authService.isUserCertifiedPartner()"
                            scope="col">Student</th>
                        <th *ngIf="authService.isUserAdmin() || authService.isUserPartner() || authService.isUserCertifiedPartner()"
                            scope="col">Email</th>
                        <th scope="col">Institute</th>
                        <th scope="col">Program</th>
                        <th scope="col">Application date</th>
                        <th scope="col">Program intake</th>
                        <th scope="col">Status</th>
                        <th scope="col"><i class="material-icons md-dark">menu</i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let application of courseApplications">
                        <th scope="row">{{application.id}}</th>
                        <td
                            *ngIf="authService.isUserAdmin() || authService.isUserPartner() || authService.isUserCertifiedPartner()">
                            <a class="cnt-link cnt-link-hover"
                                [routerLink]="['/student-profile',application.userId,'details']">{{application.studentName}}</a>
                        </td>
                        <td
                            *ngIf="authService.isUserAdmin() || authService.isUserPartner() || authService.isUserCertifiedPartner()">
                            {{application.studentEmail}}</td>
                        <td class="text-center">
                            <div [tooltip]="application.instituteName">
                                <app-image class="cursor-hand" [imageUrl]="application.instituteLogoImageUrl"
                                    [height]="50" [width]="50"
                                    [routerLink]="['/institutes',application.instituteId,utilService.replaceSpaceWithChar(application.instituteName)]">
                                </app-image>
                            </div>
                        </td>
                        <!-- <td>{{application.courseTitle}}</td> -->
                        <td>
                            <div class="cursor-hand cnt-link cnt-link-hover" [routerLink]="['/courses',application.courseId,utilService.replaceSpaceWithChar(application.instituteName), utilService.replaceSpaceWithChar(application.courseTitle)]">
                                {{application.courseTitle}}</div>
                        </td>
                        <td>{{application.applicationDate}}</td>
                        <td>{{utilService.formatIntakeDate(application.courseStartDate)}}</td>
                        <td>{{application.applicationStatus.value}}</td>
                        <td>
                            <div class="d-flex">
                                <div>
                                    <a class="nav-item nav-link navbar-text valign-center"
                                [routerLink]="['/student/course-applications', application.id, 'details']"
                                tooltip="View application">
                                <i class="material-icons md-dark md-18">visibility</i><span></span></a>
                                </div>

                                <div *ngIf="application.applicationStatus.value == 'In Progress'">
                                    <app-confirmation-modal [entityId]="application.id" [btnType]="'icon'"
                                        [btnIcon]="'delete'" (confirmationResponse)="onConfirmationResponse($event)">
                                    </app-confirmation-modal>
                                </div>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>

            <div class="text-center" *ngIf="courseApplications == null || courseApplications.length == 0">
                <div class="cnt-header-txt cnt-txt-xl txt-bold txt-opac">No program applications found</div>
            </div>
        </div>

        <div class="pagination justify-content-center" *ngIf="totalCount > pageSize">
            <pagination [totalItems]="totalCount" [itemsPerPage]="pageSize" [(ngModel)]="currentPage"
                [rotate]="pageRotate" [maxSize]="pageMaxSize" previousText="&lsaquo;" nextText="&rsaquo;"
                (pageChanged)="pageChanged($event)">
            </pagination>
        </div>
    </div>
</div>