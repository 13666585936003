import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';
import { SocialCredentials } from '../model/social-credentials.model';
import { ResetPassword } from '../model/reset-password.model';
import { AccountActivationRequest } from '../model/account-activation-request.model';
import { UserRole } from '../enums/user-role.enum';
import { UtilService } from './util.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  URL_LOGIN = '/auth/signin';
  URL_ACCOUNT_DISABLED = '/auth/account/disabled';
  URL_STUDENT_DASHBOARD = '/student/dashboard';
  URL_ADMIN_DASHBOARD = '/admin/dashboard';

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: LoggerService,
    private exceptionService: ExceptionService,
    private utilService: UtilService
  ) { }

  public login(value: any): Observable<any> {
    return this.http.post(AppConstants.endpoint.auth.login, value, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public socialLogin(socialCredentials: SocialCredentials): Observable<any> {
    return this.http.post(AppConstants.endpoint.auth.loginSocial, socialCredentials, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  public activateAccount(accountActivationRequest: AccountActivationRequest): Observable<any> {
    return this.http.put(AppConstants.endpoint.auth.activate, accountActivationRequest, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  getUserProfile(): Observable<any> {
    return this.http.get(AppConstants.endpoint.auth.user_profile)
    .pipe(catchError(this.exceptionService.handleError));
  }

  public sendActivateAccountRequest(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', email);
    
    return this.http.get(AppConstants.endpoint.auth.activate_request, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public forgotPassword(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', email);
    
    return this.http.get(AppConstants.endpoint.auth.password.forgot, {params: params})
      .pipe(catchError(this.exceptionService.handleError));
  }

  public resetPassword(resetPassword: ResetPassword): Observable<any> {
    return this.http.post(AppConstants.endpoint.auth.password.reset, resetPassword, this.utilService.getHeaders())
      .pipe(catchError(this.exceptionService.handleError));
  }

  // logout the user and redirect to login page
  public logout() {
    localStorage.removeItem(AppConstants.authTokenKey);
    this.utilService.removeSessionStorageData(AppConstants.key.session.search_course);
    //localStorage.removeItem(AppConstants.authUserKey);
    this.router.navigateByUrl(this.URL_LOGIN);
  }

  /** 
   * Check if we have a logged in user
  */
  public isUserLoggedIn(): boolean {
    let isTokenExpired = jwtHelper.isTokenExpired(this.getAuthToken());
    // this.logger.debug('User login status : ' + !isTokenExpired);
    return !isTokenExpired;
    //return false;
  }

  public getLoggedInUser() {
    return jwtHelper.decodeToken(this.getAuthToken());
  }

  public getLoggedInUserId() {
    return this.getLoggedInUser().sub;
  }

  public getLoggedInUserEmail() {
    // We are using email as the username
    return this.getLoggedInUser().email;
  }

  public getLoggedInUserFullName() {
    if (this.getLoggedInUser()) {
      return this.getLoggedInUser().name;
    } else {
      return null;
    }    
  }

  public getLoggedInUserProfileImageUrl() {
    if (this.getLoggedInUser()) {
      return this.getLoggedInUser().profileImageUrl;
    } else {
      return null;
    }    
  }

  public getLoggedInUserCompanyId() {
    return this.getLoggedInUser().companyId;
  }

  public getLoggedInUserAgencyId() {
    return this.getLoggedInUser().agencyId;
  }

  public getLoggedInUserRole(): string {
    // this.logger.debug('The decoded token : ' + JSON.stringify(jwtHelper.decodeToken(this.getAuthToken())));
    let role = 'GUEST';
    if (this.isUserLoggedIn()) {
      role = this.getLoggedInUser().role;
    }
    return role;
  }

  public isLoggedInUserEnabled(): boolean {
    //console.log('User account : ' + JSON.stringify(this.getLoggedInUser()))
    if (this.getLoggedInUser().enabled == 'true')
      return true;
    else
      return false;
  }

  public isLoggedInUserApproved(): boolean {
    if (this.getLoggedInUser().approved == 'true')
      return true;
    else
      return false;
  }

  public isUserAdmin(): boolean {
    let result = false;
    if (UserRole[UserRole.ADMIN] == this.getLoggedInUserRole()) {
      result = true;
    }

    return result;
  }

  public isUserStudent(): boolean {
    let result = false;
    if (UserRole[UserRole.STUDENT] == this.getLoggedInUserRole()) {
      result = true;
    }

    return result;
  }

  public isUserPartner(): boolean {
    let result = false;
    if (UserRole[UserRole.PARTNER] == this.getLoggedInUserRole()) {
      result = true;
    }

    return result;
  }

  public isUserCertifiedPartner(): boolean {
    let result = false;
    if (UserRole[UserRole.PARTNER_CERT] == this.getLoggedInUserRole()) {
      result = true;
    }

    return result;
  }

  public getAuthToken() {
    let token = localStorage.getItem(AppConstants.authTokenKey);
    return token;
  }

  public setAuthToken(token: string) {
    localStorage.setItem(AppConstants.authTokenKey, token);
    this.logger.debug('Setting the auth token :' + token);
  }

  /**
   * 
   * @param returnUrl The original url which user tried to access before loggin in
   */
  public redirectLoggedInUserByRole(returnUrl: string) {
    this.logger.debug('User account enabled : ' + this.isLoggedInUserEnabled());
    if (this.isLoggedInUserEnabled()) {
      this.logger.debug('User enabled, redirecting to messages');
      if (UserRole[UserRole.STUDENT] == this.getLoggedInUserRole()) {
        this.logger.debug('Login successful - Redirecting student');
        if (returnUrl != null) {
          this.router.navigateByUrl(returnUrl);           
        } else {
          this.router.navigateByUrl(this.URL_STUDENT_DASHBOARD);           
        }        
      }
      if (UserRole[UserRole.ADMIN] == this.getLoggedInUserRole() ||
          UserRole[UserRole.PARTNER] == this.getLoggedInUserRole() ||
          UserRole[UserRole.PARTNER_CERT] == this.getLoggedInUserRole()) {
        this.logger.debug('Login successful - Redirecting admin');
        if (returnUrl != null) {
          this.router.navigateByUrl(returnUrl);           
        } else {
          this.router.navigateByUrl(this.URL_ADMIN_DASHBOARD);           
        }                 
      }      
    } else {
      this.logger.debug('User disabled, redirecting to error page');
      this.router.navigateByUrl(this.URL_ACCOUNT_DISABLED);
    }

  }


}
