import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    (function ($) {
      $(document).ready(function () {
        
				/*-------------------------------------
				OwlCarousel
				-------------------------------------*/
        $("#app-partner .owl-carousel").owlCarousel({
          loop:false,
          margin:10,
          nav:false,
          autoplay:true,
          autoplayTimeout:5000,
          stagePadding:50,
          dots:false,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
              },
              1000:{
                  items:6
              }
          }
          })


      });
    })(jQuery);

  }

}
