import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ApiError } from '../../model/api-error';
import { GuestService } from '../../service/guest.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { LoggerService } from '../../service/logger.service';
import { LayoutService } from '../../service/layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  apiError: ApiError;
  newsletterForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
    private guestService: GuestService,
    private logger: LoggerService,
    public layoutService: LayoutService,
    public formUtils: FormUtilsService) { }

  ngOnInit() {
    this.createForm()
    
  }

  createForm() {  
    this.newsletterForm = this.fb.group({
      email: [null, [Validators.required]]      
    });
  }

  onSubmit() {
    // this.apiError = null;
    // this.logger.debug('Submitted form value : ' + JSON.stringify(this.studentEducationHistoryForm.value));
    
    if (this.newsletterForm.valid) {
      //this.isFormSubmit = true;

      this.guestService.newsletterSignup(this.newsletterForm.value.email).subscribe(
        data => {
          // this.apiError = null;  
          this.apiError = new ApiError();
          this.apiError.message='Thank you for signing up for the Newsletter';  
          this.newsletterForm.reset();        
        },
        error => {
          this.logger.error('Error : ' + JSON.stringify(error));
          // this.apiError = error;
        }      
      );
      
    } else {
      // show validation error
      //this.logger.debug('Submitted form value is not valid');
      this.formUtils.markFormGroupTouched(this.newsletterForm); 
    }
  }

  onClickClose() {
    this.apiError = null;
  }

}
