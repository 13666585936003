<div class="container-fluid h-100">
    <div class="row flex-nowrap h-100">

      <app-side-bar></app-side-bar>

      <div class="container-body">
        <div class="input-group search-section">
          <input type="search" class="form-control search-section__input" placeholder="Search here..."
            aria-label="Search here..." aria-describedby="button-search">
          <button class="btn search-section__icon" type="button" id="button-search"><i
              class="bi bi-search"></i></button>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>