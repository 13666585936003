import { Injectable, Inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { formatDate } from '@angular/common';

import { IntakeDate } from '../model/intake-date.model';
import { StaticData } from '../model/static-data.model';
import { ActivatedRoute } from '@angular/router';
//import { AuthService } from './auth.service';
//import { HelperProfileSummary } from '../model/helper-profile-summary.model';

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    //private headersForEnglish = new Headers({'Content-Type': 'application/json', 'Accept-Language': 'en-us'} );
    //private headers = new Headers({'Content-Type': 'application/json'});

    constructor(
        //private authService: AuthService
    ) { }

    /**
     * Replace the {id} path param in the given url and return a new url
     */
    public prepareUrl(url: string, id: any): string {
        let searchValue = /{id}/gi;
        let preparedUrl = url.replace(searchValue, id);
        return preparedUrl;
    }

    // public getHeaders(): Headers {
    //     return this.headers;
    // }

    public getHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                //'Authorization' : `Bearer ${ this.authService.getAuthToken() }`
            })
        };
    }
    public getMultiPartFormDataHeaders() {
        return {
            headers: new HttpHeaders({
                //'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
                "enctype": "multipart/form-data"
            })
        };
    }

    public getJSONHeader() {
        let httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return httpHeaders;
        // headers: new HttpHeaders({
        //     'Content-Type': 'application/json'
        // });

    }

    /**
   * Create a date object from the date string.
   * The string should be in DD/MM/YYYY format
   * @param dateString 
   */
    public createDateFromString(dateString: string): Date {
        let date: Date = null;
        if (dateString != null) {
            let dateParts = dateString.split('/');
            let day = parseInt(dateParts[0], 10);
            let month = parseInt(dateParts[1], 10);
            let year = parseInt(dateParts[2], 10);
            date = new Date(year, month - 1, day);
        }

        return date;
    }

    // returns yyyy-mm-dd format
    public formatCurrentDate = () => {
        const date = new Date();

        let month: any = date.getMonth() + 1;
        let day: any = date.getDate();
        const year = date.getFullYear();

        if (month < 10) {
            month = '0' + month.toString();
        }
        if (day < 10) {
            day = '0' + day.toString();
        }

        return (year + '-' + month + '-' + day);
    }

    public formatIntakeDate(value: string) {
        let formattedDate = null;
        if (value != null) {
            formattedDate = formatDate(this.createDateFromString(value), 'MMM yyyy', 'en_US');
        }

        return formattedDate;
    }

    public generateCourseIntakesDateOptions(): IntakeDate[] {
        let dateList: IntakeDate[] = [];
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth();

        for (let i = 0; i < 24; i++) {
            //this.logger.debug('Generated date : ' +  formatDate(new Date(currentYear, currentMonth + i, 1), 'MMM yyyy', 'en_US'));
            let startDate = new Date(currentYear, currentMonth + i, 1);
            let intakeDate = new IntakeDate();
            intakeDate.startDate = formatDate(startDate, 'dd/MM/yyyy', 'en_US');
            intakeDate.formattedStartDate = formatDate(startDate, 'MMM yyyy', 'en_US');
            //dateList.push(formatDate(new Date(currentYear, currentMonth + i, 1), 'MMM yyyy', 'en_US'));
            dateList.push(intakeDate);

        }

        return dateList;
    }

    /**
   * Create a date object from the date string.
   * The string should be in DD/MM/YYYY format
   * @param dateString 
   */
    public convertToISODateFormat(dateString: string): string {
        //isoDateString = "2011-10-05T14:48:00Z";
        //20202-09-03
        let isoDateString: string = null;
        if (dateString != null) {
            let dateParts = dateString.split('-');
            /* let day = parseInt(dateParts[0], 10);
            let month = parseInt(dateParts[1], 10);
            let year = parseInt(dateParts[2], 10);
    
            isoDateString = year + '-' + month + '-' + day + 'T' + dateAndTimePart[1] + 'Z'; */
            isoDateString = dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2] + 'T00:00:00Z';
        }

        return isoDateString;
    }



    /* public filterHelperProfilesByName(name: string, helperProfileSummaries: HelperProfileSummary[]): HelperProfileSummary[] {
        console.log('Filter helpers by name : ' + name);
        return helperProfileSummaries.filter(
            helperProfileSummary => helperProfileSummary.name.toUpperCase() == name.toUpperCase()
        );
    } */

    /**
     * Search a array of object to find out the matching objects based on a property value.
     * The search uses a partial search on value
     * @param arrayValues List of objects
     * @param propertyName Property name of the object on which serach need to be performed
     * @param searchString search string value
     * 
     * @returns array of filterd objects with matching criteria
     */
    public filterValuesByProperty(arrayValues: any[], propertyName: string, searchString: string): any[] {
        console.log('Filter array by property : ' + propertyName);
        /* return arrayValues.filter(
            value => value[propertyName].toUpperCase() == searchString.toUpperCase()
        ); */
        return arrayValues.filter(
            value => value[propertyName].toUpperCase().includes(searchString.toUpperCase())
        );
    }

    /**
     * Exclude the static data items having matching DB id given in the exclusion list
     * @param staticDataList - List of static data
     * @param exclusionList  - List of DB ID which need to be excluded
     */
    public filterStaticDataById(staticDataList: StaticData[], exclusionList: any): StaticData[] {
        return staticDataList.filter(staticData => {
            for (let key of exclusionList) {
                //console.log('Exlusion list entry : ' + key);
                if (staticData.id == key) {
                    //console.log('Excluding the key : ' + key);
                    return false;
                }
            }
            return true;
        });
    }

    /**
     * Store the data in session store
     * @param key Session storage unique identifier key 
     * @param value Data to be stored
     */
    public setSessionStorageData(key: string, value: any) {
        sessionStorage.setItem(key, value)
    }

    /**
     * Get the data from the session store
     * @param key 
     */
    public getSessionStorageData(key: string): any {
        return sessionStorage.getItem(key);
    }

    /**
     * Store the object data in session store
     * @param key Session storage unique identifier key 
     * @param value Data to be stored
     */
    public setSessionStorageObjectData(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value))
    }

    /**
     * Get the object data from the session store
     * @param key 
     */
    public getSessionStorageObjectData(key: string): any {
        return JSON.parse(sessionStorage.getItem(key));
    }

    public removeSessionStorageData(key: string): any {
        sessionStorage.removeItem(key);
    }

    public scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    /**
     * Converts the given value to lowercase and replace the space character with "-"
     * @param value The string value
     */
    replaceSpaceWithChar(value: string): string {
        let regex = /\s+/g;
        let result = value.toLocaleLowerCase().replace(regex, '-');
        // this.logger.debug('Original value : ' + value);
        // this.logger.debug('Replaced value : ' + result);
        return result;
    }

}