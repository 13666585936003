import { Component, OnInit, TemplateRef, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmationResponse } from '../../model/confirmation-response.model';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input()
  entityId: number;  

  @Input()
  btnType: string;

  @Input()
  btnText: string;

  @Input()
  btnIcon: string;

  @Input()
  isFormSubmit: boolean;

  @Output()
  confirmationResponse = new EventEmitter<ConfirmationResponse>();

  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-dialog-centered'});  
    //this.modalRef = this.modalService.show(template, {class: 'modal-sm'});  // 
    //this.modalRef = this.modalService.show(template);    
  }

  confirm() {
    //console.log('You have confirmed');
    this.emitResponse(true);
    this.modalRef.hide();
  }

  decline() {
    //console.log('You have declined');
    this.emitResponse(false);
    this.modalRef.hide();
  }

  emitResponse(isConfirmed: boolean) {
    let response = new ConfirmationResponse();
    response.entityId = this.entityId;
    response.isConfirmed = isConfirmed;
    
    this.confirmationResponse.emit(response);
  }

}
