import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  addDescriptionMetaTag(content: string) {
    this.metaService.addTag( { name: 'description', content: content});
  }

  addMetaTag(name: string, content: string) {
    //this.metaService.addTag( { name:'description',content:"Tenesse institute, Alberta"});
    this.metaService.addTag( { name: name, content: content});
  }
}
