import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiError } from '../../model/api-error';

import { AppConstants } from '../../app.constants';

import { AuthService } from '../../service/auth.service';
import { FormUtilsService } from '../../service/form-utils.service';
import { UtilService } from '../../service/util.service';
import { NoteService } from '../../service/note.service';
import { LayoutService } from '../../service/layout.service';
import { LoggerService } from '../../service/logger.service';
import { Note } from 'src/app/model/note.model';
import { NoteFilter } from 'src/app/model/note-filter.model';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  @Input()
  entityType: string;

  @Input()
  entityId: number;

  apiError: ApiError;
  isFormSubmit = false;

  noteForm: UntypedFormGroup;
  notes: Note[];

  // pagination variables
  pageRotate = true; // to center the page no - pagination component
  pageMaxSize = 5;  // no of visible pages - pagination component
  pageCount = 1; // assume that we have atleast 1 page available, actual count will be returned from backend
  totalCount = 0; // total no of pages, returned from backend
  pageSize = 0; // configured page size for the view, returned from nackend
  currentPage = 1; // by default, load the first page - keeps the track of the page


  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,    
    private noteService: NoteService,
    public layoutService: LayoutService,
    private logger: LoggerService,
    public authService: AuthService,
    public utilService: UtilService,
    public formUtils: FormUtilsService 
  ) { }

  ngOnInit() {
    this.createForm();
    this.getNotes(this.currentPage);
  }

  createForm() {  
    this.noteForm = this.fb.group({      
      entityType: [null],   
      entityId: [null],   
      text: [null],
      attr1: [null],
      attr2: [null]      
    });

    
  }

  getNotes(pageNumber: number) {
    let noteFilter = new NoteFilter();
    noteFilter.entityType = this.entityType;
    noteFilter.entityId = this.entityId;

    this.noteService.getNotes(noteFilter, pageNumber).subscribe(        
        resp => {  
          //this.isLoading = false;        
          this.pageCount = Number(resp.headers.get(AppConstants.http_header_page_count));
          this.totalCount = Number(resp.headers.get(AppConstants.http_header_total_count));
          this.pageSize = Number(resp.headers.get(AppConstants.http_header_page_size));
          this.notes = resp.body;
          this.logger.debug('Got the notes data : ' + JSON.stringify(this.notes.length));          
          this.isFormSubmit = false;
        },
        error => {         
          this.logger.debug('Error while fetching notes : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }
      );
  }

  onSubmit() {
    this.apiError = null;
    this.noteForm.get('entityType').setValue(this.entityType);
    this.noteForm.get('entityId').setValue(this.entityId);

    this.logger.debug('Submitted form value : ' + JSON.stringify(this.noteForm.value));
    
    if (this.noteForm.valid) {
      this.isFormSubmit = true;
      
      this.noteService.createNote(this.noteForm.value).subscribe(
        data => {
          this.logger.debug('Successfully created note : ' + JSON.stringify(data));
          this.apiError = null;
          this.isFormSubmit = false;
          this.noteForm.reset();
          // refresh the notes list
          this.getNotes(this.currentPage);
        },
        error => {
          this.logger.error('Create note failed : ' + JSON.stringify(error));
          this.apiError = error;
          this.isFormSubmit = false;
        }      
      );
      
    } else {
      // show validation error
      this.logger.debug('Submitted form value is not valid');
      this.formUtils.markFormGroupTouched(this.noteForm); 
    } 
  }

}
