import { Component, Input, OnInit } from '@angular/core';

import { ApiError }  from '../../model/api-error';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss']
})
export class ApiErrorComponent implements OnInit {

  @Input()
  public apiError: ApiError;

  @Input()
  public alertClass: string;

  constructor() { }

  ngOnInit() {
    if (this.alertClass == null) {
      this.alertClass='alert-danger';
    }
  }

  onClickClose() {
    this.apiError = null;
  }

}
