import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

import { ApiError } from '../model/api-error';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor(private loggerService: LoggerService) { }

  public handleError(error: HttpErrorResponse) {
    let apiError: ApiError;
    //console.error('An error occurred :', JSON.stringify(error));
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
      apiError = new ApiError();
      apiError.message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ` + JSON.stringify(error.error));
        apiError = error.error;
        if (apiError.message == null) {
          apiError.message = 'Server exception. Please contact College Admin helpdesk.'
        }
    }
    // return an ErrorObservable with a user-facing error message
    // return new ErrorObservable(
    //   'Something bad happened; please try again later.');
    //return new ErrorObservable(apiError);
    return throwError(apiError);
  }

}
