// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.collegeadmin.digifydemo.com/studyoffer',
  //apiBaseUrl: 'http://localhost:8081/studyoffer/api',
  // google cloud - study-offer-dev - Google Maps integration
  //googleStudyOfferApiKey: 'AIzaSyDtEHsmmGZi4lpGGfJ-XwygHtu0MHoFKHg',
  googleStudyOfferApiKey: 'AIzaSyDKG90yn2-Y6d2XGFH9FZ0w_Ssg7xTe_kc', // given by paras
  // google reCAPTCHA - study offer dev - https://www.google.com/recaptcha/about/
  googleReCaptchaSiteKey: '6LfJabYZAAAAAJZb-AT1y6XQbKEYUV3LeK3gavmy',
  // facebook auth - https://developers.facebook.com/apps/ - App - study-offer-dev
  facebookAppId: '350651859631388'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
