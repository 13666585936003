import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';

// google reCaptcha
// import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

// ngx-translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


// ngx-bootstrap modules
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { SearchProgramModule } from './modules/search-program/search-program.module';

// environment
import { environment } from '../environments/environment';

// google maps
// TO DO: Need To enable
//import { AgmCoreModule } from '@agm/core';

// social login
 
/*
let config = new SocialAuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    //provider: new FacebookLoginProvider("1626121807609891")
    // Ghelp-dev
    //provider: new FacebookLoginProvider(AppConstants.socialAuthProvider.facebook.appId)
    provider: new FacebookLoginProvider(environment.facebookAppId)
  }
]);
*/

let config = [
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    //provider: new FacebookLoginProvider("1626121807609891")
    // Ghelp-dev
    //provider: new FacebookLoginProvider(AppConstants.socialAuthProvider.facebook.appId)
    provider: new FacebookLoginProvider(environment.facebookAppId)
  }
];
 
export function provideSocialConfig() {
  return config;
}

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './public/home/home.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AuthInterceptor } from './security/auth.interceptor';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { AboutComponent } from './public/about/about.component';
import { PartnersComponent } from './public/partners/partners.component';
import { ContactComponent } from './public/contact/contact.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { FacebookLoginProvider, SocialLoginModule } from '@abacritt/angularx-social-login';
import { SideBarComponent } from './layout/side-bar/side-bar.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import {MatStepperModule} from '@angular/material/stepper';
import { LoaderService } from './service/loader.service';
import { LoaderComponent } from './public/loader/loader.component';
import { PageNotFoundComponent } from './public/page-not-found/page-not-found.component';
import { NgxPaginationModule } from 'ngx-pagination';
//import { AppTooltipDirective } from './directive/tooltip-ellipsisfyMe.directive';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    WorkInProgressComponent,
    AboutComponent,
    PartnersComponent,
    ContactComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    PrivacyPolicyComponent,
    SideBarComponent,
    LandingPageComponent,
    LoaderComponent
    //AppTooltipDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    NgxPaginationModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    LayoutModule,
    SearchProgramModule,
    // RecaptchaModule,  //this is the recaptcha main module
    // RecaptchaFormsModule, //this is the module for form incase form validation


    // TO DO: Need To enable
    /*
    AgmCoreModule.forRoot({
      apiKey: environment.googleStudyOfferApiKey,
      libraries: ['places']
    }),
    */
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),  
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      //useFactory: provideSocialConfig
      useValue : {
        autoLogin: false,
        providers : [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            //provider: new FacebookLoginProvider("1626121807609891")
            // Ghelp-dev
            //provider: new FacebookLoginProvider(AppConstants.socialAuthProvider.facebook.appId)
            provider: new FacebookLoginProvider(environment.facebookAppId)
          }
        ]
      }
    },
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
