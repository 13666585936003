import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppConstants } from '../app.constants';

import { FileUploadResponse } from '../model/file-upload-response.model';
import { Document } from '../model/document.model';
import { UtilService } from './util.service';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { ExceptionService } from './exception.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private authService: AuthService,
    private logger: LoggerService,
    private exceptionService: ExceptionService
  ) { }

  public uploadImage(file: File): Observable<FileUploadResponse> {
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('userRole', this.authService.getLoggedInUserRole());
    formData.append('id', this.authService.getLoggedInUserId());
    return this.http.post<FileUploadResponse>(AppConstants.endpoint.file.upload.image, formData)
      .pipe(catchError(this.exceptionService.handleError));
  }

  public uploadDocument(file: File): Observable<Document> {
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('userRole', this.authService.getLoggedInUserRole());
    formData.append('id', this.authService.getLoggedInUserId());
    return this.http.post<Document>(AppConstants.endpoint.file.upload.document, formData)
      .pipe(catchError(this.exceptionService.handleError));
  }

  /* public downloadDocument(documentId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.file.download.document, documentId);
    this.logger.debug('Prepared url : ' + url);
    return this.http.get<any>(url)
      .pipe(catchError(this.exceptionService.handleError));
  } */

  public downloadDocument(documentId: number): Observable<any> {
    let url = this.utilService.prepareUrl(AppConstants.endpoint.file.download.document, documentId);
    this.logger.debug('Prepared url : ' + url);
    return this.http.get(url, {headers: new HttpHeaders(), responseType: 'blob'})
      .pipe(catchError(this.exceptionService.handleError));
  }

}
