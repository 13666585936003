import { Component, OnInit, HostListener } from '@angular/core';

import { LayoutService  } from '../../service/layout.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    public layoutService: LayoutService) { }

  ngOnInit() {
  }

}
